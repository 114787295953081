import { Routes, Route, Switch, Redirect } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from "./firebase/firebase";

import './App.scss';
import { AuthProvider } from "./utils/AuthContext";
import Plantmeting from './components/Plantmeting';
import NevaX from './components/NevaX';
import HarvestPredictionRow from './components/HarvestPredictionRow';
import HarvestPredictionDepartment from './components/HarvestPredictionDepartment';
import LayoutProduction2 from './components/LayoutProduction2';
import Dashboard from './pages/Dashboard';
import TeleopRos from './components/Teleop';
import Login from './components/auth/Login'
import Signup from './components/auth/Signup';

import PrivateRoutes from "./utils/PrivateRoutes";

import { Theme } from '@carbon/react';
import { useEffect } from "react";

function App() {

  return (
    <AuthProvider>
      <Theme theme="g100">
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route element={<PrivateRoutes/>}>
              <Route path="/home" element={<Dashboard/>}></Route>
              <Route path="/plantmeting" element={<Plantmeting/>}></Route>
              <Route path="/nevax" element={<NevaX/>}></Route>
              <Route path="/LayoutProduction2" element={<LayoutProduction2/>}></Route>
              <Route path="/HarvestPredictionDepartment" element={<HarvestPredictionDepartment/>}></Route>
              <Route path="/HarvestPredictionRow" element={<HarvestPredictionRow/>}></Route>
              <Route path="/teleop" element={<TeleopRos/>}></Route>
            </Route>
          </Routes>
        </div>
      </Theme>
    </AuthProvider>
  );
}

export default App;
