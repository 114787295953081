// Greenhouse.jsx

import React, { useState } from 'react';
import './Greenhouse.scss';


const Greenhouse = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupText, setPopupText] = useState('');

  const plots = new Array(76).fill(null); // 76 plots on each side of the road
  const subPlots = new Array(47).fill(null); // 47 sub-plots within each plot

  const specialNumbers = [488, 440, 485, 477, 451, 463, 380, 428, 430, 501, 489, 490];

  const getSubPlotClass = (plotNumber, subPlotIndex) => {
    if (specialNumbers.includes(plotNumber)) {
      const randomValue = Math.random();
      if (randomValue < 0.2) {
        return 'red'; // 20% chance of being red
      }
      return 'blue'; // 80% chance of being blue
    }

    const randomValue = Math.random();
    if (randomValue < 0.1) {
      return 'attention';
    } else if (randomValue < 0.3) {
      return '';
    } else {
      return '';
    }
  };

  const handleMouseEnter = (plotNumber, subPlotIndex, cellClass) => {
    if (cellClass === 'red') {
      setPopupText(`This is a sample text for plot ${plotNumber}, sub-plot ${subPlotIndex}.`);
      setPopupVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setPopupVisible(false);
  };

  return (
    <div className="greenhouse">
      {popupVisible && <div className="popup">{popupText}</div>}
      <div className="side">
        {plots.map((_, plotIndex) => {
          const plotNumber = 351 + plotIndex * 2;
          return (
            <div key={`plot-left-${plotIndex}`} className="plot">
              <div className="plot-number">
                {plotNumber}
              </div>
              {subPlots.map((_, subPlotIndex) => {
                const cellClass = getSubPlotClass(plotNumber, subPlotIndex);
                return (
                  <div
                    key={`subplot-left-${plotIndex}-${subPlotIndex}`}
                    className={`sub-plot ${cellClass}`}
                    onMouseEnter={() => handleMouseEnter(plotNumber, subPlotIndex, cellClass)}
                    onMouseLeave={handleMouseLeave}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="road" />
      <div className="side">
        {plots.map((_, plotIndex) => {
          const plotNumber = 352 + plotIndex * 2;
          return (
            <div key={`plot-right-${plotIndex}`} className="plot">
              <div className="plot-number">
                {plotNumber}
              </div>
              {subPlots.map((_, subPlotIndex) => {
                const cellClass = getSubPlotClass(plotNumber, subPlotIndex);
                return (
                  <div
                    key={`subplot-right-${plotIndex}-${subPlotIndex}`}
                    className={`sub-plot ${cellClass}`}
                    // onClick={() => handleCellClick(plotNumber, subPlotIndex, cellClass)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Greenhouse;
