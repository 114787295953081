import React, {Component,useState} from 'react';
import ReactDOM from 'react-dom';
import testim from '../../assets/images/1639131371_capture.jpg'
import { getDatabase, onValue,get,child,set } from "firebase/database";
import { getStorage, ref, getDownloadURL,getBlob, getStream,listAll } from "firebase/storage";
import { Button } from '@carbon/react';
import { Add,ZoomIn,ZoomOut } from '@carbon/icons-react';
import{TileGroup,RadioTile,Switch,ContentSwitcher,Tile,InlineLoading} from '@carbon/react';
import Magnifier from "react-magnifier";

import {app,database,dbRef,storage} from "../../firebase/firebase"
import { usePapaParse } from 'react-papaparse';
import axios from 'axios'
import { thresholdScott } from 'd3';
export default class Canvas extends React.Component{
    constructor(props) {
        super(props);
        //added state 
        
        this.state={
            current_index_selected:0,
            groeiResult:0,
            stamDikte:0,
            BladBreedte:0,
            TomaatBreedte:0,
            BladLengte:0,
            curr_total_res:"",
            curr_total_per:[],
            isDown: false,
            previousPointX:0,
            previousPointY:0,
            x:0,
            y:0,
            x1:0,
            y1:0,
            d:0,
            dep1:0,
            dep2:0,
            points:[],
            currentPointx:0,
            currentPointy:0,
            results_depth:undefined,
            loadingActive:'inactive',
            data_to_be_saved:{}
        }
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleZoomout = this.handleZoomout.bind(this);
        this.handleOnclickSave = this.handleOnclickSave.bind(this);
        this.handleOnclickReset = this.handleOnclickReset.bind(this);

        // this.getBlobCSV =this.getBlobCSV.bind(this)

    }
    handleOnclickSave(){
        this.setState({loadingActive:'active'})
        // console.log("this.state.groeiResult,this.state.stamDikte,this.state.BladLengte,this.state.BladBreedte,this.state.TomaatBreedte")
        // console.log(this.state.groeiResult,this.state.stamDikte,this.state.BladLengte,this.state.BladBreedte,this.state.TomaatBreedte)
        this.writeUserData(this.state.groeiResult,this.state.stamDikte,this.state.BladLengte,this.state.BladBreedte,this.state.TomaatBreedte)
    }
    handleOnclickReset(){
        const canvas = ReactDOM.findDOMNode(this.refs.canvas);

        this.setState({
            points:[],
            groeiResult:0,
            stamDikte:0,
            TomaatBreedte:0,
            BladBreedte:0,
            BladLengte:0,
            loadingActive:'finished'
        })
        var ctx = canvas.getContext("2d");
        const image = new Image();
        image.src = testim
        image.onload =() => {
            ctx.drawImage(image,0,0,1280,720);
        }
        ctx.fillRect(0, 0, 1280, 720);

    }
    handleZoomout(){
        console.log("im here")
        const canvas = ReactDOM.findDOMNode(this.refs.canvas);
        var ctx = canvas.getContext("2d");
        //ctx.translate(pt.x,pt.y);
       ctx.scale(40,40);
       //ctx.translate(-pt.x,-pt.y);



    }

    total_with(res_api){

    }
     writeUserData(groeiResult, stamdikte,lengteblad,breedteblad,diametertomato) {
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            console.log("DATE",date)
         let go = JSON.stringify(Date().toLocaleString())   

        let newdbRef= child(dbRef,  `/Sensors/measurements/`+go) 
         
        set(newdbRef, {
          data:{  
          stamdikte: JSON.stringify(stamdikte),
          groeiResult : JSON.stringify(groeiResult),
          lengteblad: JSON.stringify(lengteblad),
          breedteblad:JSON.stringify(breedteblad),
          diametertomato:JSON.stringify(diametertomato)
        }
       }
        )

        this.setState({
            loadingActive:'finished'
        })
      }

    render() {
        return (
            <> 
             {/* <div className="bx--col-lg-4 ">
                <h2>Tools here</h2>
                </div>     */}
            <div className="bx--col-lg-10 ">
            <ContentSwitcher 
            onChange={ 
                (obj)=>{
                    let { index, name, text } = obj;
                    this.setState({
                        current_index_selected:index,
                        current_param_selected:name,
                        points:[]
                        
                    })
                    console.log("obj", index,name)
                }
                

              }>
          <Switch name="Groei" text="Groei " />
          <Switch name="Stamdikte" text="Stamdikte" />
          <Switch name="Blad-lengte" text="Blad lengte" />
          <Switch name="Blad-Breedte" text="Blad breedte" />
          <Switch name="Tomaat-Breedte" text="Tomaat breedte" />

           


        </ContentSwitcher>
       

        </div>
        <div className="bx--col-lg-2 ">
            <Button hasIconOnly size="sm" onClick={this.handleOnclickSave} >Save</Button>
        
            
            <Button hasIconOnly size="sm" kind="danger--tertiary" onClick={this.handleOnclickReset}>Reset</Button>
            <InlineLoading 
            onSuccess={()=>this.setState({loadingActive:'finished'})}
            status={this.state.loadingActive}
            successDelay={30}
            
            ></InlineLoading>
        
        </div>

        <div className='bx--col-lg-2'>
        <Tile><h5>{this.state.groeiResult.toFixed(1)} </h5>mm</Tile>
        </div>
        <div className='bx--col-lg-2'>

        <Tile><h5>{this.state.stamDikte.toFixed(1)} </h5>mm</Tile>
        </div>
        <div className='bx--col-lg-2'>

        <Tile><h5>{this.state.BladLengte.toFixed(1)} </h5>mm</Tile>
        </div>
        <div className='bx--col-lg-2'>

        <Tile><h5>{this.state.BladBreedte.toFixed(1)} </h5>mm</Tile>
        </div>
        <div className='bx--col-lg-2'>

        <Tile><h5>{this.state.TomaatBreedte.toFixed(1)} </h5>mm</Tile>
        </div>



        <div className='bx--col-lg-12'>

                <canvas id="canvas" ref="canvas"
                        // width={1280}
                        // height={720}
                        onMouseDown={
                            e => {
                                let nativeEvent = e.nativeEvent;
                                this.handleMouseDown(nativeEvent);
                            }}
                        onMouseMove={
                            e => {
                                let nativeEvent = e.nativeEvent;
                                this.handleMouseMove(nativeEvent);
                            }}    
                        onMouseUp={
                            e => {
                                let nativeEvent = e.nativeEvent;
                                this.handleMouseUp(nativeEvent);
                            }}
                />

                </div>
                <div className="bx--col-lg-12 ">


    </div>
    
                <div className="bx--col-lg-12 ">
                <Button onClick={this.handleZoomout}  size='sm' kind='ghost'><ZoomOut size="30"/></Button>
                <Button  size='sm' kind='ghost'><ZoomIn size="30"/></Button>
                {/* <Button  onClick={this.handlePrevImage} size='sm' kind='ghost'>Prev</Button> */}
                {/* <Button  onClick={this.handleNextImage} size='sm' kind='ghost'>Next</Button> */}

</div>
            </>    
        );
    }
    getBlobCSV (filename){
        //if(x,y,x2,y2!==0){
        // console.log('STORAGE REF',ref(storage, '1639131371_dcapture.csv'))    
        getBlob(ref(storage, '1639131371_dcapture.csv'))
        .then((stream) => {
          // `url` is the download URL for 'images/stars.jpg'
          console.log(stream)
      const {readString} = usePapaParse()
      readString(stream,{
          worker:true,
          header:true,
          complete: (results) =>{
        // console.log("RES",results.data[y][x])
        // console.log("RES2",results.data[y2][x2])
        this.setState({
            // x:x,
            // y:y,
            // x2:x2,
            // y2:y2,
            // dep1:results.data[y][x],
            // dep2:results.data[y2][x2]
            results_depth: results.data
        })
        // return  results.data[y][x], results.data[y2][x2]

          }
          
      })
       //console.log("Streaming",stream)
          
        })
        .catch((error) => {
      console.log("ERRORE:",error)  });
    
    //}
    }
    handleMouseDown(event){ //added code hereconsole.log(event.offsetX, event.offsetY);    
        
        
        if(Array.isArray(this.state.points) && this.state.points.length >0){
            //let dep1,dep2=this.getBlobCSV(this.state.x,this.state.y,this.state.x2,this.state.y2)

            this.setState({x2:this.state.points[this.state.points.length-1].x,y2:this.state.points[this.state.points.length-1].y,d2:this.state.points[this.state.points.length-1].d})
                // console.log('last',this.state.x_last, this.state.y_last)
                console.log('logs_last',this.state.points[this.state.points.length-1])
                //console.log('DATA STATS: XY,X2Y2',this.state.x,this.state.y,this.state.x2,this.state.y2); 

                        if(this.state.points.length>1){

            
                }

        }
        this.state.points.push({x:event.offsetX, y:event.offsetY,d:this.state.results_depth[event.offsetY][event.offsetX]})

        this.setState({x:this.state.points[this.state.points.length-1].x,y:this.state.points[this.state.points.length-1].y,d:this.state.points[this.state.points.length-1].d})
        
        console.log('logs_current',this.state.points[this.state.points.length-1])

        // console.log('POINTS:',this.state.points); 
        console.log("RESULT_DEPTH d1", this.state.d)
        console.log("RESULT_DEPTH x1", this.state.x)
        console.log("RESULT_DEPTH y1", this.state.y)



        this.setState({
            isDown: true,
            // x: event.offsetX,
            // y:event.offsetY,
            // previousPointX:event.offsetX,
            // previousPointY:event.offsetY,
        },()=>{    
            const canvas = ReactDOM.findDOMNode(this.refs.canvas);    
            // var x = event.offsetX;
            // var y = event.offsetY;
            // this.setState({
            //     currentPointx:x,
            //     currentPointy:y
            // })
            var ctx = canvas.getContext("2d");
            
            //console.log(x,y);
            // ctx.moveTo(x,y);
            // ctx.lineTo(x+1,y+1);
            // ctx.stroke();
            
        })

    }
    drawLine(ctx, begin, end,res, stroke = 'blue', width = 1) {
    // console.log('DRAW',begin,end)
        if (stroke) {
            ctx.strokeStyle = stroke;
        }
    
        if (width) {
            ctx.lineWidth = width;
        }
    
        ctx.beginPath();
        ctx.moveTo(...begin);
        ctx.lineTo(...end);
        ctx.stroke();
        ctx.font = "10px Arial";
        ctx.fillText(res, begin[0]-2, begin[1]-2);
        
    }
    handleMouseMove(event){

    }
    handleMouseUp(event){
        this.setState({
            isDown: false
        });
        if(this.state.isDown){
            const canvas = ReactDOM.findDOMNode(this.refs.canvas);
            var x = event.offsetX;
            var y = event.offsetY;
            var ctx = canvas.getContext("2d");

            // ctx.moveTo(this.state.previousPointX,this.state.previousPointY);
            // ctx.lineTo(x,y);
            // ctx.stroke();
            // ctx.closePath();
        }
        if((this.state.d || this.state.d2) == 0) {
            console.log("DEPTH ZERO FOUND!!!!!!!")
            if(this.state.d2==0.0 && this.state.d>0.0){
                this.setState({d2:this.state.d})
            }else if(this.state.d==0.0 && this.state.d2>0.0){
                this.setState({d:this.state.d2})

            }
        }
        if(this.state.points.length>1 ){
            // this.getBlobCSV(this.state.x,this.state.y,this.state.x2,this.state.y2)
            axios.post('https://hell-api-53danfpkeq-ey.a.run.app/add?a='+this.state.d+'&'+'b='+this.state.d2+'&'+'x1='+this.state.x+'&'+'y1='+this.state.y+'&'+'x2='+this.state.x2+'&'+'y2='+this.state.y2)
            .then(response => {this.setState({cur_api_res:response.data})
        console.log('hallo data: '+ response.data + 'FOR--->'+'a='+this.state.d+'&'+'b='+this.state.d2+'&'+'x1='+this.state.x+'&'+'y1='+this.state.y+'&'+'x2='+this.state.x2+'&'+'y2='+this.state.y2 )
        // this.state.curr_total_res.push({x:this.state.x,y:this.state.y,x2:this.state.x2,y2:this.state.y2,res:response.data})
        console.log(this.state.cur_api_res)

        this.drawLine(ctx,[this.state.x,this.state.y],[this.state.x2,this.state.y2],response.data)
        if(this.state.current_index_selected ===0){
            this.state.groeiResult+=response.data

        }else if(this.state.current_index_selected ===1){
            this.state.stamDikte+=response.data
        }else if(this.state.current_index_selected ===2){
            this.state.BladLengte+=response.data

        }else if(this.state.current_index_selected ===3){
            this.state.BladBreedte+=response.data

        }else if(this.state.current_index_selected ===4){
            this.state.TomaatBreedte+=response.data

        }
        })
            .catch((error) => {
                console.log(error);
              });
    }


    }
    
    componentDidMount() {
        const canvas = ReactDOM.findDOMNode(this.refs.canvas);
        const ctx = canvas.getContext("2d");
        //ctx.fillStyle = 'rgb(0,100,155)';
        const image = new Image();
        image.src = testim
        image.onload =() => {
            ctx.drawImage(image,0,0,1280,720);
        }
        ctx.fillRect(0, 0, 1280, 720);
        get(child(dbRef, `/Sensors`)).then((snapshot) => {
  if (snapshot.exists()) {
    console.log(snapshot.val());

  } else {
    console.log("No data available");
  }
}).catch((error) => {
  console.error("ERROR",error);
});
// this.ref = ref(storage,'1639131371_dcapture.csv')
//                 getBlob(this.ref).then((data) => {
                    
//             console.log("HALLO")
//             console.log(data)
//         })

    // Simple POST request with a JSON body using axios
    const article = { a: 1.0,b:2.0 };
    this.getBlobCSV("F")
    // Create a reference under which you want to list
    const listRef = ref(storage);
    
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          console.log("LISTS",itemRef)
          
        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });

    }

    
}