import axios from "axios";
import { storeAPIData, getAPIData } from '../cacheIDB/indexedDB'; // Import IndexedDB functions


// const getAPIData = async () => {
//   const response = await axios.get("https://fastapi-myt-nua3ywjn2a-ew.a.run.app/read_analysis_data/stoffels/section-1/row-488/2023-07-17-11%3A31/?limit=2", {
//     crossOrigin: true
//   });
//   return response.data;
// };

// export  async function getFireData() {
//   const data = await getAPIData();
//   // console.log("fetch",data)
//   return data;
// }
const timeLimit = 4 * 60 * 60 * 1000; // 24 hours in milliseconds

const getAPIDataWeeks = async () => {
  const response = await axios.get("https://fastapi-myt-nua3ywjn2a-ew.a.run.app/frontend_dropdown/stoffels/section-2/", {
    crossOrigin: true
  });
  return response.data;
};

export  async function getFireDataWeek() {
  // First, try to get data from IndexedDB
  const cachedData = await getAPIData('fireDataWeek',timeLimit);
  if (cachedData) {
    return cachedData;
  }
  const data = await getAPIDataWeeks();
  // console.log("fetch",(Object.keys(data.keys)).sort())
    // Store the fetched data in IndexedDB for future use
  await storeAPIData('fireDataWeek', data);
  return data;
}


const getAPIDataCountAll = async (row, date) => {
  
  const url = `https://fastapi-myt-nua3ywjn2a-ew.a.run.app/graph/stoffels/section-2/${row}/${date}/?cam_num=2`
  const response = await axios.get(url, {
    crossOrigin: true
  });

  console.log("URL",url)
  return response.data;
};


export  async function getFireDataCountAll(row,date,cam_num) {
  const cacheKey = `DataCountAll_${row}_${date}_${cam_num}`;

  const cachedData = await getAPIData(cacheKey,timeLimit);
  if (cachedData) {
    return cachedData;
  }
  const data = await getAPIDataCountAll(row,date);
  // console.log("fetch",(Object.keys(data.keys)).sort())
  await storeAPIData(cacheKey, data);

  return data;
}


// https://fastapi-myt-nua3ywjn2a-ew.a.run.app/forecast_details/stoffels/section-1/row-451/2023-07-27-12%3A35/

const getAPIForecastDetails = async (row, date) => {
  const url = `https://fastapi-myt-nua3ywjn2a-ew.a.run.app/forecast_details/stoffels/section-1/${row}/${date}/`
  const response = await axios.get(url, {
    crossOrigin: true
  });

  console.log("URL",url)
  return response.data;
};


export  async function getFireForecastDetails(row,date) {
  const cacheKey = `DataCountAll_${row}_${date}`;

  const cachedData = await getAPIData(cacheKey,timeLimit);
  if (cachedData) {
    return cachedData;
  }
  const data = await getAPIForecastDetails(row,date);
  // console.log("fetch",(Object.keys(data.keys)).sort())
  await storeAPIData(cacheKey, data);

  return data;
}

const getAPIStoffels = async () => {
  const url = `https://fastapi-myt-nua3ywjn2a-ew.a.run.app/StoffelsAPI/`
  const response = await axios.get(url, {
    crossOrigin: true
  });

  console.log("URL",url)
  return response.data;
};


export  async function getFireStoffelsData() {
  const cachedData = await getAPIData('FireStoffelsData',timeLimit);
  if (cachedData) {
    return cachedData;
  }
  const data = await getAPIStoffels();
  // console.log("fetch",(Object.keys(data.keys)).sort())
  await storeAPIData('FireStoffelsData', data);

  return data;
}


const getweeklyForecast = async (weeknumber) => {


  const url = `https://fastapi-myt-nua3ywjn2a-ew.a.run.app/forecast_details/stoffels/section-2/${weeknumber}/`
  const response = await axios.get(url, {
    crossOrigin: true
  });

  console.log("URL",url)
  return response.data;
};




const Weeklyforecast2024 = async () => {
  const url = `https://fastapi-myt-nua3ywjn2a-og.a.run.app/forecast_weekly/`
  const response = await axios.get(url, {
    crossOrigin: true
  });

  console.log("Weekly forecast",response.data)
  return response.data;
};

export  async function getWeeklyforecast2024() {
  // const cachedData = await getAPIData('2024dataharvest',timeLimit);
  // if (cachedData) {
  //   return cachedData;
  // }
  const data = await Weeklyforecast2024();
  // console.log("fetch",(Object.keys(data.keys)).sort())
  await storeAPIData('FireStoffelsData', data);

  return data;
}