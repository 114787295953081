import React, { useState } from 'react';
import { Modal, TextInput, Button } from '@carbon/react';
import './AddHarvestModal.scss';
import { set, child,push } from "firebase/database";
import { dbRef,auth } from "./../../firebase/firebase"; // Replace with the actual path to your Firebase config

const HarvestModal = ({ onClose }) => {
  const [weekNumber, setWeekNumber] = useState('');
  const [weight, setWeight] = useState('');
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const newErrors = {};

    // Check if week number is filled
    if (!weekNumber.trim()) {
      newErrors.weekNumber = "Week number is required!";
    }

    // Check if weight is filled
    if (!weight.trim()) {
      newErrors.weight = "Weight is required!";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are errors, don't save and set the errors to state
      setErrors(newErrors);
      return;
    }

    // If no errors:
    const data = {
      weekNumber,
      weight,
    };

    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split('T')[0];
    const username = auth.currentUser.displayName || auth.currentUser.email.replace(/[.#$@[\]]/g, '_'); // Fallback to email if displayName is not available



// Create a reference to the new collection and date-based key
const harvestDataRef = child(dbRef, `WeeklyHarvestData/${username}/${currentDate}`);
// const newHarvestRef = push(harvestDataRef);

// Save to Firebase
set(harvestDataRef, data)
  .then(() => {
    console.log("Data saved successfully.");
    // Clear the fields
    setWeekNumber('');
    setWeight('');
    onClose();
  })
  .catch((error) => {
    console.error("Error saving data: ", error);
  });
  };

  return (
    <Modal
      open
      modalHeading="Add Weekly Harvest"
      modalLabel=""
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestClose={onClose}
      onRequestSubmit={handleSave}
      danger={false}
      className="harvest-modal"
    >
      <div className="harvest-modal-content">
        <div className="form-field">
          <label className="form-label">Week Number:</label>
          <TextInput
            id="weekNumber"
            value={weekNumber}
            onChange={(e) => {
              setWeekNumber(e.target.value);
              if (errors.weekNumber) {
                // Reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, weekNumber: null }));
              }
            }}
            invalid={!!errors.weekNumber}
            invalidText={errors.weekNumber || ""}
            className="form-input"
          />
        </div>

        <div className="form-field">
          <label className="form-label">Weight (kg/m²):</label>
          <TextInput
            id="weight"
            value={weight}
            onChange={(e) => {
              setWeight(e.target.value);
              if (errors.weight) {
                // Reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, weight: null }));
              }
            }}
            invalid={!!errors.weight}
            invalidText={errors.weight || ""}
            className="form-input"
          />
        </div>
      </div>
    </Modal>
  );
};

export default HarvestModal;
