import useWeatherAppStore from "../../../hooks/UseWeatherApp";

export default function TimeSelector() {
  const { 
    currentHour,
    date, 
    tzOffset,
    activeHourIndex,
    activeDateIndex,
    setActiveHourIndex, 
    setCurrentHour 
  } = useWeatherAppStore()
  
  function handleTimeClick(index) {
    setActiveHourIndex(index);

    if (activeDateIndex !== 0) {
      const selectedDate = new Date(date.getTime());

      selectedDate.setDate(selectedDate.getDate() + activeDateIndex);
      selectedDate.setHours(12 + 4*(index - 2) - tzOffset, 0, 0, 0);
      
      setCurrentHour(selectedDate.toISOString().slice(0, 13));
    } else {
      const selectedDate = new Date(date.getTime());
  
      const hours = selectedDate.getHours()
      selectedDate.setHours(hours + 3*index - tzOffset, 0, 0, 0);
  
      setCurrentHour(selectedDate.toISOString().slice(0, 13));
    }
    console.log(currentHour)
  }

  const hourElems = []

  for (let i = 0; i < 6; i++) {
    hourElems.push(
      <div className="cds--col no-padding" id="datepicker-column">
        <h6 
          className={
            `date ${i !== activeHourIndex ? "secondary-text" : null} 
          `} 
          onClick={() => {
            handleTimeClick(i)
          }}
          >
            {activeDateIndex === 0 &&
              <>
                {i !== 0 ? `${(date.getHours() + 3*i) % 24}:00`  : "Now"}
              </>
            }
            {activeDateIndex !== 0 &&
              <>
                {`${(4 + 4*i) % 24}:00`}
              </>
            }
        </h6>
      </div>
    )
  }
  return (
    <div className="cds--grid no-padding" >
      <div className="cds--row bottom-gap">
        {hourElems}
      </div>
    </div>
  )
}