import React, { useState } from 'react';
import { TextInput, Toggle, Button, ComposedModal, ModalHeader, ModalBody, ModalFooter } from 'carbon-components-react';
import './LightingPlan.scss';

const LightingPlan = ({ isOpen, onClose }) => {
  const [weekNumber, setWeekNumber] = useState('');
  const [error, setError] = useState(null);
  const [lightingPlan, setLightingPlan] = useState({
    Monday: { active: false, lightAmount: '' },
    Tuesday: { active: false, lightAmount: '' },
    Wednesday: { active: false, lightAmount: '' },
    Thursday: { active: false, lightAmount: '' },
    Friday: { active: false, lightAmount: '' },
    Saturday: { active: false, lightAmount: '' },
    Sunday: { active: false, lightAmount: '' },
  });

  const handleWeekChange = (event) => {
    setWeekNumber(event.target.value);
    if (error) setError(null);
  };

  const handleDayChange = (day) => {
    setLightingPlan((prevPlan) => {
      const updatedPlan = { ...prevPlan };
      updatedPlan[day].active = !updatedPlan[day].active;
      if (!updatedPlan[day].active) {
        updatedPlan[day].lightAmount = '';
      }
      return updatedPlan;
    });
  };

  const handleLightAmountChange = (day, value) => {
    setLightingPlan((prevPlan) => {
      const updatedPlan = { ...prevPlan };
      updatedPlan[day].lightAmount = value;
      setError(null);
      return updatedPlan;
    });
  };

  const savePlan = () => {
    for (const day in lightingPlan) {
      if (lightingPlan[day].active && !lightingPlan[day].lightAmount.trim()) {
        setError(`Light amount for ${day} is required when the toggle is On.`);
        return;
      }
    }

    if (!weekNumber.trim()) {
      setError('Week number is required!');
      return;
    }

    console.log('Saved lighting plan:', lightingPlan, 'Week Number:', weekNumber);
    onClose();
  };

  return (
    <ComposedModal open={isOpen} onClose={onClose}>
      <ModalHeader title="LED Lighting Plan" />
      <ModalBody>
        <div className="lighting-plan">
          <TextInput
            id="week-number"
            labelText="Week Number"
            value={weekNumber}
            onChange={handleWeekChange}
            invalid={!!error}
            invalidText={error}
          />
          {Object.entries(lightingPlan).map(([day, plan]) => (
            <div key={day}>
              <Toggle
                id={day}
                labelText={day}
                toggled={plan.active}
                onToggle={() => handleDayChange(day)}
                labelA="Off"
                labelB="On"
              />
              {plan.active && (
                <TextInput
                  id={`${day}-light-amount`}
                  labelText="Light Amount (μmol/m2/s)"
                  value={plan.lightAmount}
                  onChange={(event) => handleLightAmountChange(day, event.target.value)}
                  invalid={!plan.lightAmount.trim() && plan.active}
                  invalidText={`Light amount is required when ${day} is On.`}
                />
              )}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" onClick={onClose}>
          Close
        </Button>
        <Button kind="primary" onClick={savePlan}>
          Save Lighting Plan
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
};

export default LightingPlan;
