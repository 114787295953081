export default function DataTile({label, color, value, unit, growth}) {

    return(
      <div 
        className="cds--tile"
        style={{
          paddingBottom: "0.7rem",
          paddingTop: "0.9rem",
          background: color,
        }}
      >
        <div 
          className="cds--grid cds--grid--condensed" 
          style={{
            paddingLeft: "1.5rem", 
            paddingRight: "1rem",
          }}
          >
          <div className="cds--row">
            <div className="cds--col">
              <div><h5>{label}</h5></div>
            </div>
            <div className="cds--col-lg-5">
            <h5 style={{
              color: growth > 0 ? "#08bdba" : growth < 0 ? "#fa4d56" : null
            }}
            >
              <span>
                {growth > 0 ? "+" : null}
              </span>
              {growth}
              %
            </h5>
            </div>
          </div>
          <div className="cds--row" 
          style={{
            backgroundColor: "#c1c7cd",
            height: "1px",
            marginTop: "13px",
            marginBottom: "5.5px",
          }}>
          </div>
          <div className="cds--row">
            <div className="cds--col">
              <div className="important--data">
                {value} <span><h4 className="unit--data">{unit}</h4></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
