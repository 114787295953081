import React from "react";
import { LineChart,StackedBarChart,ScatterChart } from '@carbon/charts-react'
import data from './data.json'
import data2 from './data2.json'
import data3 from './data3.json'

import options from './optionsWeek1.json'
import options2 from './optionsWeek2.json'
import options3 from './options3.json'
import options4 from './options4.json'
import "@carbon/charts/styles.css";
import "./plex-and-carbon-components.css";
import Canvas from "../MeasurementCanvas/MeasureAPI";
import { Dropdown,Loading } from "@carbon/react";


// Import getAPIData function
import { getFireDataWeek,getFireDataCountAll } from '../api/api';

import DefaultPage from "../../utils/DefaultPage";


class Plantmeting extends React.Component {
    
    state = {
      loading: true,
data,options,options2,options3,data2,data3,options4,
fire_data: null ,// Initialize the fire_data state,
dataPerWeek:[],
weekNumber: "Week 2", // Initialize the weekNumber state
weekitems: [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5",
  "Week 6",
  "Week 7",
  "Week 8",
  "Week 9",
  "Week 10",
]
,
rowNumber: "row-488", // Initialize the weekNumber state
rowitems: [
  "row-488",
  "row-490",
  "row-492",
],
currentDateReference:null,
dataGraph:data,
dataGraphSecondWeek:data,
allGraphData:[],
linePlot:[]
	};
  
  async componentDidMount() {
    this.setState({ loading: true });

    try {
      const dataWeek= await getFireDataWeek();
      const sorted = (Object.keys(dataWeek.keys)).sort();
  
      this.setState({ weekitems: sorted, weekNumber: sorted[sorted.length - 1] });
      const rownumbercurrent = dataWeek.keys[sorted[sorted.length - 1]].scanned_rows[0];
      const rowItemsCurrent = dataWeek.keys[sorted[sorted.length - 1]].scanned_rows;
      console.log("WeekData", dataWeek);

      const dateReferenceCurrent = dataWeek.keys[sorted[sorted.length - 1]].date_reference[0];
      console.log("current date refrence",dateReferenceCurrent);
      // We're using the callback function here.
      this.setState({ 
          rowNumber: rownumbercurrent, 
          rowitems: rowItemsCurrent, 
          fire_data: dataWeek,
          currentDateReference: dateReferenceCurrent 
        }, async () => {
          // Now that state has been updated, we can call getFireDataCountAll
          const dataCountAll = await getFireDataCountAll(this.state.rowNumber, this.state.currentDateReference);
          console.log("DATAGRAPH", dataCountAll);
          this.setState({allGraphData:dataCountAll})
          // Map over dataCountAll.count_all_crops and convert the value property to a number
          const mappedData = dataCountAll.count_all_crops.map(item => ({
            ...item,
            value: Number(item.value)
          }));
          
          this.setState({ dataGraph: mappedData });
          const mappedDataSecondWeek = dataCountAll.second_week_forecast.map(item => ({
            ...item,
            value: Number(item.value)
          }));
          this.setState({ dataGraphSecondWeek: mappedDataSecondWeek }, () => {
  console.log("SECOND WEEK", this.state.dataGraphSecondWeek)
});
          const linePlot_per_band = dataCountAll.Line_plot.map(item => ({
            ...item,
            value: Number(item.value)
          }));
          this.setState({linePlot:linePlot_per_band})
      });
      this.setState({ loading: false });

    } catch (error) {
      console.log("Error occurred:", error);
    } finally{
     
        this.setState({ loading: false });
      

    }
  }

  


  


  

  handleChangeWeeks = async ({ selectedItem }) => {
    
    console.log("Selected Item:", selectedItem);
    
    // Get the row numbers for the selected week
    const rowItemsCurrent = this.state.fire_data.keys[selectedItem].scanned_rows;
    const rownumbercurrent = rowItemsCurrent[0];
    const dateReferenceCurrent = this.state.fire_data.keys[selectedItem].date_reference[0];

    this.setState({ 
      weekNumber: selectedItem, 
      rowNumber: rownumbercurrent,
      currentDateReference: dateReferenceCurrent, 
      rowitems: rowItemsCurrent 
    }, async () => {
      // Calling the API after state is updated
      const dataCountAll = await getFireDataCountAll(this.state.rowNumber,this.state.currentDateReference);
      console.log("DATAGRAPH", dataCountAll);
      this.setState({ dataGraph: dataCountAll.count_all_crops,dataGraphSecondWeek:dataCountAll.second_week_forecast,linePlot:dataCountAll.Line_plot });
    });
  };
  
  
  handleChangeRows = async ({ selectedItem }) => {
    const [rowNumber, side] = selectedItem.split("-"); // Assuming the format is "row-488-left"
    const dateReferenceCurrent = this.state.fire_data.keys[this.state.weekNumber].date_reference[0];
  
    console.log("Selected Item:", selectedItem);
    this.setState({ 
      rowNumber: rowNumber, 
      side: side, // new state to hold the side information
      currentDateReference: dateReferenceCurrent 
    }, async () => {
      // Modify the API call to include the side information if needed
      const dataCountAll = await getFireDataCountAll(this.state.rowNumber, this.state.currentDateReference, this.state.side);
      console.log("DATAGRAPH", dataCountAll);
      this.setState({ 
        dataGraph: dataCountAll.count_all_crops,
        dataGraphSecondWeek: dataCountAll.second_week_forecast,
        linePlot: dataCountAll.Line_plot 
      });
    });
  };
  
  
  render = () => {
    if (this.state.loading) {
      
      return  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Loading description="Active loading indicator" withOverlay={false} small/></div>
    } else {
      return (
        <div className="bx--grid bx--grid--full-width" style={{background: "#ECECEC"}}>
          <div className="bx--row" style={{marginBottom: '60px'}}>
            <DefaultPage/>
          </div>
          <div className="bx--row ">
            <div className="bx--col-lg-6">
              <Dropdown
                size="lg"
                label="Select Weeks"
                id="weeks"
                items={this.state.weekitems}
                onChange={this.handleChangeWeeks}
                selectedItem={this.state.weekNumber}
              />
            </div>
            <div className="bx--col-lg-6">
              <Dropdown
                label="Select Rows"
                id="rows"
                size="lg"
                items={this.state.rowitems}
                onChange={this.handleChangeRows}
                selectedItem={this.state.rowNumber}
              />
            </div>
          </div>
          <div className="bx--row ">
            <div className="bx--col-lg-12 ">
              <StackedBarChart
                data={this.state.dataGraph}
                options={this.state.options}
              />
              <StackedBarChart
                data={this.state.dataGraphSecondWeek}
                options={this.state.options2}
              />
              <LineChart
                data={this.state.linePlot}
                options={this.state.options4}
              />
              {/* <ScatterChart
                data={this.state.data2}
                options={this.state.options3}
              /> */}
            </div>
            <div className="bx--col-lg-12 ">
              {/* Other components, if any */}
            </div>
          </div>
        </div>
      );
    }
  };
  
};





export default Plantmeting;