import { useQuery } from "react-query";
import axios from "axios";

import useWeatherAppStore from "../../../hooks/UseWeatherApp";

export default function WeatherData(props) {
  const apiUrl = "https://api.open-meteo.com/v1/forecast?latitude=51.998&longitude=4.3751&hourly=temperature_2m,relativehumidity_2m,dewpoint_2m,precipitation,shortwave_radiation&daily=temperature_2m_max,precipitation_sum,windspeed_10m_max,shortwave_radiation_sum&timezone=Europe%2FBerlin"

  async function getWeatherData() {
    const response = await axios.get(apiUrl);
    return response.data;
  }

  const { 
    isLoading, 
    isError, 
    isSuccess, 
    data
  } = useQuery('weather', async () => getWeatherData());
  
  const { currentHour } = useWeatherAppStore()
  const variable = props.variable;
  const isSecondary = props.secondary === "true" ? true : false
  const Icon = props.icon

  if (isLoading || isError) {
    return (
      <>
        {!isSecondary && (
          <div className="important--data-weather">
            --
          </div>
        )}
        {isSecondary && (
          <>
            <h5>
              <span className="secondary-text">
                {props.label}:
              </span>
              <span>&nbsp;&nbsp;</span>
              <span className="secondary-text">
                --&nbsp;&nbsp;
              </span>
            </h5>
          </>
        )}
      </>
    )
  } else if (isSuccess) {  
    const hourlyData = {}; 

    data.hourly.time.forEach((timestamp, index) => {
      hourlyData[timestamp.slice(0, 13)] = {
        value: data.hourly[variable][index],
        unit: data.hourly_units[variable]
      };
    });

    const { value, unit } = hourlyData[currentHour] || { value: null, unit: null };

    return (
      <>
        {!isSecondary && (
          <> 
            <div className="important--data-weather">
              {value} <span className="unit--data-weather">{unit}</span>
            </div>
          </>
        )}
        {isSecondary && (
          <>
            <h5>
              <span><Icon className="sub-svg" size={20}/>&nbsp;&nbsp;&nbsp;</span>
              <span className="secondary-text">
                {props.label}:
              </span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>
                {value}
              </span>
              <span>&nbsp;</span>
              <span className="secondary-text">
                {unit}
              </span>
            </h5>
          </>
        )}
      </>
    )
  }
}