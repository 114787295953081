import React, { useState } from 'react';
import { Modal, TextInput, Button, FileUploader } from '@carbon/react';
import Papa from 'papaparse'; // Import Papa Parse
import './AddClimateData.scss';

const AddClimateData = ({ onClose }) => {
  const [weekNumber, setWeekNumber] = useState('');
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const newErrors = {};

    // Check week number is filled
    if (!weekNumber.trim()) {
      newErrors.weekNumber = "Week number is required!";
    }

    // Check file is uploaded
    if (!file) {
      newErrors.file = "CSV file is required!";
    }

    if (Object.keys(newErrors).length > 0) {
      // If there are errors, don't save and set the errors to state
      setErrors(newErrors);
      return;
    }

    // If no errors: read CSV data
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const data = {
          weekNumber,
          fileData: results.data, // Here is the parsed CSV data
        };
        console.log(data);
      },
      error: (err) => {
        console.error("Error while parsing CSV:", err);
        setErrors({ file: "Error while parsing CSV file!" });
      },
    });

    // Clear the fields
    setWeekNumber('');
    setFile(null);

    onClose();
  };

  return (
    
    <Modal
      open
      modalHeading="Add Climate Data"
      modalLabel=""
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestClose={onClose}
      onRequestSubmit={handleSave}
      danger={false}
      className="climate-data-modal"
    >
      <div className="climate-data-modal-content">
        <div className="form-field">
          <label className="form-label">Week Number:</label>
          <TextInput
            id="weekNumber"
            value={weekNumber}
            onChange={(e) => {
              setWeekNumber(e.target.value);
              if (errors.weekNumber) {
                // reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, weekNumber: null }));
              }
            }}
            invalid={!!errors.weekNumber}
            invalidText={errors.weekNumber || ""}
            className="form-input"
          />
        </div>

        <div className="form-field">
          <label className="form-label">Upload CSV:</label>
          <FileUploader
            accept={['.csv']}
            buttonLabel="Add file"
            filenameStatus="edit"
            disableLabelChanges={true}
            multiple={false}
            onChange={(e) => {
              setFile(e.target.files[0]);
              if (errors.file) {
                // reset the error when the user starts typing
                setErrors((errs) => ({ ...errs, file: null }));
              }
            }}
            invalid={!!errors.file}
            invalidText={errors.file || ""}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddClimateData;
