import { Theme } from "@carbon/react";

import HeaderComponent from "../components/header/Header";

export default function DefaultPage({ children }){
    return (
      <>
        <HeaderComponent />
        <Theme theme="white">
          <div className="bx--grid bx--grid--full-width no-padding">
            <div className="bx--row">
              <div className="bx--col-lg-12">
                {children}
              </div>  
            </div>
          </div>
        </Theme>
      </>
    );
  };