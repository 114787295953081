import TimeSelector from "./TimeSelector";
import DateSelector from "./DateSelector";
import WeatherData from "./WeatherData";
import useWeatherAppStore from "../../../hooks/UseWeatherApp";

import {
  HumidityAlt,
  DewPoint,
  Rain,
  UvIndex,
  MostlyCloudy
} from "@carbon/icons-react"

export default function WeatherWidget() {
  const { 
    activeHourIndex,
    activeDateIndex,
  } = useWeatherAppStore()

  return (
    <>
      <div 
        className="cds--tile"
        style={{
          background: 
            "radial-gradient(ellipse at 20% 100%, rgba(237,245,255,1) 0%, rgba(255,255,255,1) 130%)"
        }}
      >
        <div className="cds--grid no-padding">
          <div className="cds--row bottom-gap" id="weather-content">
            <div className="cds--col-lg-6">
              <h3>
                <MostlyCloudy 
                  size={32} 
                  className="svg-icon"
                />
                <span>&nbsp;&nbsp;</span>
                  Weather
              </h3>
              <div className="important--data-label">
                {activeDateIndex === 0 && activeHourIndex === 0 ? "current" : "expected"}
              </div>
            </div>
            <div className="cds--col-lg-10">
              <WeatherData 
                label="Humidity" 
                variable="relativehumidity_2m" 
                secondary="true"
                icon={HumidityAlt}
              />
              <WeatherData 
                label="Precipitation" 
                variable="precipitation" 
                secondary="true"
                icon={Rain}
              />
              <WeatherData 
                label="Dewpoint" 
                variable="dewpoint_2m" 
                secondary="true"
                icon={DewPoint}
              />
              <WeatherData 
                label="SW Radiation" 
                variable="shortwave_radiation" 
                secondary="true"
                icon={UvIndex}
              />
            </div>
          </div>
          <div className="cds--row">
            <div className="cds--col">
              <WeatherData 
                variable="temperature_2m"
              />
            </div>
          </div>
        </div>
        <TimeSelector />
        <DateSelector />
      </div>
    </>
  )
}