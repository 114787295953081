import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthErrorCodes } from "firebase/auth";

import { useAuth } from "../../utils/AuthContext";

import logo from "../../assets/images/logo-white.png";

import {
  InlineNotification,
  Button,
  Form,
  FormGroup,
  Stack,
  TextInput
} from "@carbon/react"

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (password === confirmPassword ) {
      try {
        await auth.signup(email, password);
        navigate("/home");
      } catch (err) {
        if (
          err.code === AuthErrorCodes.INVALID_PASSWORD ||
          err.code === AuthErrorCodes.USER_DELETED
        ) {
          setError("The email address or password is incorrect");
        } else {
          setError("Something went wrong. Try Again.")
        }
      }
    } else {
      setError("Passwords don't match.")
    }
  };

  return (
    <div style={{position:"absolute", width:"100vw", height:"100vh", backgroundColor:"black", zIndex:"-2000"}}>
    <div className="cds--grid bx--grid--full-width no-padding">
      <div className="cds--row" style={{margin:"3rem"}}>
        <div className="cds--col-lg-7 no-padding" >
          <div style={{
              backgroundImage: "linear-gradient(125deg, rgba(0,0,0,1) 70%, rgba(60,60,60,1) 100%)",
              position: "absolute", 
              top: "48%", 
              transform:"translateY(-50%)", 
              borderRadius: "50px",
              width: "40vw",
              left: "0",
              height: " 650px",
            }}
            >
              <div style={{
                  backgroundColor: "black",
                  position: "relative", 
                  top: "50%", 
                  transform:"translateY(-50%)", 
                  borderRadius: "50px",
                  width: "99.5%",
                  height: " 99.5%",
                }}
              >
                <div 
                  style={{
                    position: "absolute", 
                    top: "48%", 
                    transform:"translateY(-50%)", 
                    paddingLeft: "3.5rem"
                  }}
                >
                  <div className="cds--row" >
                    <div className="cds--col-lg-16" style={{paddingBottom: "3.5rem"}}>
                      <h2 className="main-text" style={{fontWeight:"500"}}>Create an account</h2>
                    </div>
                  </div>
                  {error && <InlineNotification variant="danger">{error}</InlineNotification>}
                  <Form onSubmit={handleSubmit}>
                    <FormGroup style={{ width: '350px' }}>
                      <Stack gap={8}>
                        <TextInput
                          id="one"
                          labelText="Enter your email"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextInput
                          id="two"
                          labelText="Enter your password"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextInput
                          id="two"
                          labelText="Confirm your password"
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button className="cds--btn cds--btn--primary btn-updated" type="submit">
                          Register
                        </button>
                      </Stack>
                      <div style={{paddingTop:"2rem"}}>
                        <Stack gap={4} >
                          <h5 style={{fontWeight: "400"}}>
                            Already have an account?
                            &nbsp;&nbsp;&nbsp;
                            <Link to="/">
                              <h5 className="link">Sign in</h5>
                            </Link>
                          </h5>
                        </Stack>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="cds--col" style={{paddingLeft:"0"}}>
          <div style={{
              backgroundImage: "linear-gradient(-35deg, rgba(0,0,0,1) 70%, rgba(60,60,60,1) 100%)",
              position: "absolute", 
              top: "48%", 
              transform:"translateY(-50%)", 
              borderRadius: "50px",
              width: "60vw",
              height: " 604px",
              right: "0",
              zIndex: "2"
            }}
            >
              <div style={{
                  backgroundColor: "black",
                  position: "relative", 
                  top: "50%", 
                  transform:"translateY(-50%)", 
                  borderRadius: "50px",
                  width: "99%",
                  height: " 99%",
                }}
              >
            <div style={{position: "absolute", top: "46%", transform:"translateY(-50%)", right:"7rem"}}>
              <img src={logo} alt="Logo" style={{height: "60px"}}/>
            </div>
            <h3 className="main-text" style={{position: "absolute", top: "56%", transform:"translateY(-50%)", right:"11rem"}}>Revolutionizing agriculture with AI.</h3>
          </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  );
};
export default Signup;