import { useState } from "react";
import { useSignOut } from 'react-firebase-hooks/auth';
import { useNavigate } from "react-router-dom";

import { 
  Button, 
  Popover, 
  PopoverContent 
} from "@carbon/react";
import {
  Header,
  HeaderMenu,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from "@carbon/react"
import {
  Notification, 
  User
} from "@carbon/icons-react"

import { useAuth } from "../../utils/AuthContext";
import logo from "../../assets/images/logo-white.png";

export default function HeaderComponent() {
  const [profileOpen, setProfileOpen] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  async function handleSignOut() {
    try {
      await auth.logout();
    } catch (err) {
        console.log(err);
    }
  }

  return (
        <>
          <Header aria-label="SKOUT platform">
            <div style={{paddingLeft: "1rem", paddingBottom:"2.2px"}}>
              <img src={logo} alt="logo" style={{height: "1rem"}}/>
            </div>
            <HeaderName href="/home" prefix={null}>
              Monitoring Platform
            </HeaderName>
            <HeaderNavigation>
              {/* <HeaderMenuItem href="/plantmeting">Plant Measurement</HeaderMenuItem> */}
              {/* <HeaderMenuItem href="#">IPM</HeaderMenuItem> */}
              {/* <HeaderMenuItem href="/HarvestPredictionDepartment">Harvest Prediction</HeaderMenuItem> */}
              {/* <HeaderMenu aria-label="Link 4" menuLinkName="Robot">
                <HeaderMenuItem href="/teleop">Tele-opeartion</HeaderMenuItem>
              </HeaderMenu> */}
            </HeaderNavigation>
              <HeaderGlobalBar>
                <HeaderGlobalAction
                  aria-label="Notifications"
                  onClick={() => {}}
                >
                  <Notification />
                </HeaderGlobalAction>
                <Popover open={profileOpen} isTabTip align="bottom-right">
                  <HeaderGlobalAction
                    aria-label="Profile"
                    onClick={() => {setProfileOpen(!profileOpen)}}
                  >
                    <User />
                  </HeaderGlobalAction>
                  <PopoverContent>
                    {auth.currentUser && (
                    <button className="cds--btn cds--btn--primary btn-updated" onClick={handleSignOut}>
                      Sign Out
                    </button>
                    )}
                    {!auth.currentUser && (
                      <>
                      <div className="cds--grid" >
                        <div className="cds--row" style={{padding: "8px"}}>
                          <button className="cds--btn cds--btn--primary btn-updated"
                            onClick={()=>{navigate("/")}}
                            >
                            Sign In
                          </button>
                          <h3>&nbsp;</h3>
                        </div>
                        <div className="cds--row" style={{padding: "8px", paddingBottom: "20px"}}>
                          <button className="cds--btn cds--btn--primary btn-updated"
                            onClick={()=>{navigate("/signup")}}
                            >
                            Sign Up
                          </button>
                          <h3>&nbsp;</h3>
                        </div>
                      </div>
                      </>
                    )}
                  </PopoverContent>
                </Popover>
              </HeaderGlobalBar>
            </Header>
        </>
  );
};
