import { create } from "zustand";

const useWeatherAppStore = create((set) => {
  const date = new Date();
  const tzOffset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();
  date.setHours(hours - tzOffset);

  return {
    date,
    tzOffset,
    activeDateIndex: 0,
    currentHour: date.toISOString().slice(0, 13),
    activeHourIndex: 0,
    setActiveDateIndex: (index) => set({ activeDateIndex: index }),
    setActiveHourIndex: (index) => set({ activeHourIndex: index }),
    setCurrentHour: (hour) => set({ currentHour: hour }),
  };
});

export default useWeatherAppStore;