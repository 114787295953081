import React from "react";
import { AreaChart,SimpleBarChart,HistogramChart,HeatmapChart,ComboChart, StackedBarChart, GroupedBarChart } from "@carbon/charts-react";
import { Link } from 'react-router-dom'
import { DataTable,Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarSearch,
  TableToolbarContent,
  TableToolbarMenu,
  TableToolbarAction,
  TableExpandHeader,
  TableSelectAll,
  TableExpandedRow,
  TableExpandRow,
  TableSelectRow, 
  Button, 
  TableRow } from '@carbon/react';

import "@carbon/charts/styles.css";
import "./plex-and-carbon-components.css";

const headerData = [
  {
    header: 'rij',
    key: 'rijnummer',
  },
  {
    header: 'bijzonderheden',
    key: 'alerts',
  },
  {
    header: 'laatste scan',
    key: 'scan',
  },
  {
    header: 'laatste weging',
    key: 'weighing',
  },
  {
    header: 'gewicht weging',
    key: 'weight',
  },
  {
    header: 'verwachte oogst - 2 weken',
    key: 'two_w',
  },
  {
    header: 'verwachte oogst - 4 weken',
    key: 'four_w',
  },
  {
    header: 'insecten/ziektes',
    key: 'disease_insect',
  },


];

// #firebase load all the data from dates today to 1 week and then fetch values and populate 
const rowData = [
  {
    id: 'a',
    rijnummer: 352,
    alerts: 'toenname oogst 6 w voorspelling',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
  {
    id: 'b',
    rijnummer: 354,
    alerts: '-',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: 'witte vlieg toenname 36 m',
  },
  {
    id: 'c',
    rijnummer: 356,
    alerts: '-',
    scan: '-',
    weighing: '-',
    weight: '-',
    two_w: '-', 
    four_w: '-', 
    six_w: '-', 
    disease_insect: '-',
  },
  {
    id: 'd',
    rijnummer: 358,
    alerts: '-',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
  {
    id: 'e',
    rijnummer: 360,
    alerts: 'toenname oogst 2 w voorspelling',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '-',
    two_w: '-', 
    four_w: '-', 
    six_w: '-', 
    disease_insect: '-',
  },
  {
    id: 'f',
    rijnummer: 362,
    alerts: 'toenname oogst 6 w voorspelling',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
  {
    id: 'g',
    rijnummer: 364,
    alerts: '-',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
  {
    id: 'h',
    rijnummer: 366,
    alerts: '-',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: 'witte vlieg toenname 86 m',
  },
  {
    id: 'i',
    rijnummer: 368,
    alerts: '-',
    scan: '-',
    weighing: '-',
    weight: '-',
    two_w: '-', 
    four_w: '-', 
    six_w: '-', 
    disease_insect: '-',
  },
  {
    id: 'j',
    rijnummer: 370,
    alerts: 'toenname oogst 4 w voorspelling',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
  {
    id: 'k',
    rijnummer: 372,
    alerts: '-',
    scan: '22-05-22 17:40',
    weighing: '22-05-22 17:40',
    weight: '101.2 kg',
    two_w: '110.6 kg', 
    four_w: '110.6 kg', 
    six_w: '110.6 kg', 
    disease_insect: '-',
  },
];

class HarvestPredictionDepartment extends React.Component {


  state ={

    dataChart: [
      {
        "group": "Voorspelling",
        "key": "7",
        "value": 650
      },
      {
        "group": "Voorspelling",
        "key": "6",
        "value": 650
      },
      {
        "group": "Voorspelling",
        "key": "5",
        "value": 550
      },
      {
        "group": "Voorspelling",
        "key": "4",
        "value": 450
      },
      {
        "group": "Voorspelling",
        "key": "3",
        "value": 460
      },
      {
        "group": "Voorspelling",
        "key": "2",
        "value": 510
      },
      {
        "group": "Voorspelling",
        "key": "1",
        "value": 500
      },
      {
        "group": "Scan",
        "key": "7",
        "value": 10
      },
      {
        "group": "Scan",
        "key": "6",
        "value": 100
      },
      {
        "group": "Scan",
        "key": "5",
        "value": 200
      },
      {
        "group": "Scan",
        "key": "4",
        "value": 300
      },
      {
        "group": "Scan",
        "key": "3",
        "value": 400
      },
      {
        "group": "Scan",
        "key": "2",
        "value": 450
      },
      {
        "group": "Scan",
        "key": "1",
        "value": 510
      },
      {
        "group": "gewogen",
        "key": "oogst 4-6-22",
        "value": 510
      },
      {
        "group": "gewogen",
        "key": "oogst 11-6-22",
        "value": 450
      },
      {
        "group": "gewogen",
        "key": "oogst 18-6-22",
        "value": 550
      },  
      {
        "group": "gewogen",
        "key": "oogst 25-6-22",
        "value": 490
      },
      {
        "group": "voorspeling 4",
        "key": "oogst 4-6-22",
        "value": 520
      },
      {
        "group": "voorspeling 4",
        "key": "oogst 11-6-22",
        "value": 440
      },
      {
        "group": "voorspeling 4",
        "key": "oogst 18-6-22",
        "value": 560
      },  
      {
        "group": "voorspeling 4",
        "key": "oogst 25-6-22",
        "value": 480
      },        
    ],
      
    optionsChart: {
      "title": "Oogstprognose rij:",
      "axes": {
        "left": {
          "mapsTo": "value",
          "stacked": true,
          "title": "Oogst (kg)",
        },
        "bottom": {
          "mapsTo": "key",
          "scaleType": "labels",
          "thresholds": [
            {
              "value": "1w",
              "label": "Custom formatter",
            }
          ]
        }
      },
      "height": "400px"
     

    }
  
  }
  GetLastRecords(howmany) {
    return ""
  }
  render = () => (
    <div className="bx--grid bx--grid--full-width">
    <div className="bx--row ">
        <h3 style={{textAlign:"left"}}>prod 2 - rij 352 t/m 372</h3>          
      </div>
    <div className="bx--row ">

    <DataTable
  rows={rowData}
  headers={headerData}>
    {({ rows, headers, getHeaderProps, getRowProps, getTableProps }) => (
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            <TableRow>
              <TableExpandHeader />
              {headers.map(header => (
                <TableHeader {...getHeaderProps({ header })}>
                  {header.header}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <React.Fragment key={row.id}>
                <TableExpandRow {...getRowProps({ row })}>
                  {row.cells.map(cell => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableExpandRow>
                {row.isExpanded && (
                  <TableExpandedRow colSpan={headers.length + 1}>                                       
                    <div className="cds--row ">
                      <GroupedBarChart
							          data={this.state.dataChart}
							          options={this.state.optionsChart}>
						          </GroupedBarChart>                     
                    </div>
                    <div className="cds--row ">
                      <br/>
                      <h6>Details laatste scan:</h6>

                      <Link to="/HarvestPredictionRow" style={{ textDecoration: 'none', color:"blue" }}>
                        <Button kind="tertiary">
                          Links
                        </Button>
                      </Link>
                      <Button kind="tertiary">
                        Rechts
                      </Button>        
                    </div>                                                
                  </TableExpandedRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </DataTable>
    
    </div>
                      
</div>

	);
};
		              
export default HarvestPredictionDepartment;



    
    
            
