import { React, useEffect, useState  } from "react";
import "@carbon/charts/styles.css";
import "@carbon/charts/styles.css";

// import HarvestPredictionDepartment from "../components/HarvestPredictionDepartment";
import HarvestPredictionDepartment from "../components/HarvestPredictionDepartment/HarvestPredictionDepartment";
// import WeatherWidget from "../components/dashboard/WeatherWidget/WeatherWidget.jsx";
import WeatherWidget from "../components/Dashboard/WeatherWidget/WeatherWidget";
// import HarvestPrediction from "../components/dashboard/HarvestPrediction";
import HarvestPrediction from "../components/Dashboard/HarvestPrediction";
import SecondaryChart from "../components/Dashboard/SecondaryChart";
import DataTile from "../components/Dashboard/DataTile";

import DefaultPage from "../utils/DefaultPage";
import {getweeklyForecastData } from '../components/api/api';
import { getWeeklyforecast2024 } from '../components/api/api';

import Greenhouse from "../components/Greenhouse/Greenhouse";
import HarvestModal from "../components/AddHarvestModal/AddHarvestModal";
import LightingPlan from "../components/LightingPlan/LightingPlan";
import AddClimateData from "../components/AddClimateData/AddClimateData";
import { Button,Loading  } from "@carbon/react";
import './Dashboard.scss'

export default function Dashboard() {

    const [isOpen, setIsOpen] = useState(false);
    const [isLightingPlanOpen, setIsLightingPlanOpen] = useState(false);
    const [isClimateDataOpen, setIsClimateDataOpen] = useState(false);
    const [weeklyForecast, setWeeklyForecast] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentWeek, setCurrentWeek] = useState(32);
    const [allWeeklyForecasts, setAllWeeklyForecasts] = useState({}); // To store all weeks' data

    const [flatData, setFlatData] = useState([]);




  
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const data = await getWeeklyforecast2024();
          // Initialize a map to hold the best value for each week
          let bestValuesByWeek = {};
          console.log("data",data)
          // Transform keys to remove 'W_' prefix and sort by week number
      const transformedAndSortedData = Object.entries(data)
      .map(([key, value]) => {
        return {
          week: parseInt(key.substring(2), 10), // Remove 'W_' and parse to integer
          values: value
        };
      })
      // Sort by week number
      .sort((a, b) => a.week - b.week)
      // Map back to expected format if necessary
      .reduce((acc, { week, values }) => {
        acc[week] = values; // Use the integer week number as the key
        return acc;
      }, {});
      console.log("Object transofemd data ",transformedAndSortedData)
          Object.entries(transformedAndSortedData).forEach(([week, values], index, array) => {
            // Parse the week number
            const weekNum = parseInt(week);
    
            // Process current week's 'week0'
            if (values.week0 >= 0.4 || weekNum === Math.min(...array.map(Number))) {
              // If week0 is above 0.3 or it's the first week, use its value
              bestValuesByWeek[week] = parseFloat(values.week0.toFixed(2));
            } else if (values.week0 < 0.3) {
              // If week0 is below 0.3, look back to the previous week's 'week1'
              const prevWeekNum = weekNum - 1;
              bestValuesByWeek[week] = parseFloat(data[prevWeekNum].week1.toFixed(2));
            }
    
            // Process future weeks' values (week1, week2, week3)
            for (let i = 1; i <= 3; i++) {
              const futureWeekNum = weekNum + i;
              bestValuesByWeek[futureWeekNum] = parseFloat(values[`week${i}`].toFixed(2));
            }
          });
    
          // Convert to the array format expected by the chart
          const flatData = Object.entries(bestValuesByWeek).map(([key, value]) => ({
            key,
            value,
            group: "Prognose-Vitalion"
          }));
    
          // Sort the data by week number
          flatData.sort((a, b) => parseInt(a.key) - parseInt(b.key));
    
          setFlatData(flatData); // Store the flattened data for the chart
          setIsLoading(false);
        } catch (error) {
          console.error("Error occurred:", error);
          setIsLoading(false);
        }
      };
    
      fetchData();
    }, []);
    
    
    
    function formatForecastDataForChart(forecastData) {
      const formattedData = [];
    
      Object.entries(forecastData).forEach(([week, values]) => {
        // Reverse to get the most recent week's data first
        const entries = Object.entries(values).reverse();
    
        // Find the first most recent value above 0.3
        const mostRecentAboveThreshold = entries.find(([_, value]) => value > 0.3);
    
        if (mostRecentAboveThreshold) {
          const [_, value] = mostRecentAboveThreshold;
          formattedData.push({
            key: week,
            value: parseFloat(value.toFixed(2)), // Ensure value is in the correct format
            group: "Prognose"
          });
        }
      });
    
      return formattedData;
    }
    
    // const chartData = formatForecastDataForChart(weeklyForecast);
    // console.log("chart data",chartData);


return (
    <>
    <DefaultPage>
    <div className="cds--grid bx--grid--full-width" id="main-grid">
        <div className="cds--row">
        <div className="cds--col-lg-13">
            <div className="cds--grid no-padding">
            <div className="cds--row">
                <div className="cds--col">
                <div 
                    className="cds--tile nofill"
                    style={{
                    background: "radial-gradient(circle at bottom right, rgba(217,251,251,1) 0%, rgba(237,245,255,1) 15%, rgba(255,255,255,1) 25%)"
                    }}
                >
<HarvestPrediction data={flatData} />                </div>
                </div>
            </div>
            <div className="cds--row">
                <div className="cds--col-lg-7">
                <WeatherWidget />
                </div>
                <div className="button-container">
    <div className="add-harvest-button">
        <Button kind="primary" onClick={() => setIsOpen(true)}>
            Add Weekly Harvest
        </Button>
        {isOpen && <HarvestModal onClose={() => setIsOpen(false)} />}
    </div>
    <div>
        <Button onClick={() => setIsLightingPlanOpen(true)}>Lighting Plan</Button>
        <LightingPlan isOpen={isLightingPlanOpen} onClose={() => setIsLightingPlanOpen(false)} />
    </div>
    <div className="add-climate-data-button">
        <Button  onClick={() => setIsClimateDataOpen(true)}>
            Add Climate Data
        </Button>
        {isClimateDataOpen && <AddClimateData onClose={() => setIsClimateDataOpen(false)} />}
    </div>
</div>
                {/* <div className="cds--col-lg-9">
                <div 
                    className="cds--tile"
                    style={{
                        background: "radial-gradient(ellipse at 95% 0%, rgba(217,251,251,1) 0%, rgba(237,245,255,1) 20%, rgba(255,255,255,1) 36%)"
                    }}
                >
                    <SecondaryChart /> 
                </div>
                </div> */}
            </div>
            </div>
        </div>
       
        {isLoading ? (
  <Loading className="some-class" withOverlay={false} small />
) : (
  <div className="cds--col-lg-3 no-padding">
    {flatData.slice(-4).map((data, index) => {
      // Extract the current and previous values from flatData
      const currentValue = data.value;
      const previousData = flatData[flatData.length - 5 + index]; // The data from the week before
      const previousValue = previousData ? previousData.value : undefined;
      
      // Calculate the growth if there's a previous value to compare to
      let growth = 'N/A';
      if (previousValue !== undefined && currentValue !== undefined) {
        const percentageChange = (((currentValue - previousValue) / previousValue) * 100).toFixed(1);
        growth = `${percentageChange}`;
      }

      // The week label is taken from the 'key' of flatData
      const label = `Week ${data.key}`;
      const value = currentValue.toFixed(2);

      return (
        <DataTile
          key={data.key} // Use data.key as the unique key for React list rendering
          label={label}
          value={value} // Append unit here
          growth={growth}
          unit="kg/m2"
          color="radial-gradient(circle at top left, rgba(217,251,251,1) 0%, rgba(237,245,255,1) 25%, rgba(255,255,255,1) 65%)"
        />
      );
    })}
  </div>
)}




        </div>
        <div className="cds--row">
        <h3>&nbsp;&nbsp;&nbsp;</h3>
        </div>
        <div className="cds--row">
  <div className="cds--col">


  </div>
</div>





        {/* <div className="bx--row">
        <div className="bx--col">
          <h2>Serre-2 Overview</h2> 
          <Greenhouse /> 
        </div>
      </div> */}
        <div className="cds--row">
        <div className="cds--col-sm">
            <h1>&nbsp;&nbsp;&nbsp;</h1>
        </div>
        </div>
        <div className="cds--row">
        <div className="cds--col-sm">
            <h1>&nbsp;&nbsp;&nbsp;</h1>
        </div>
        </div>
    </div>
    </DefaultPage>
    </>
);
} 