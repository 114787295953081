import React from "react";
import { AreaChart,SimpleBarChart,HistogramChart,HeatmapChart,ComboChart } from "@carbon/charts-react";
import { DataTable,Table,
      TableBody,
      TableCell,
      TableContainer,
      TableHead,
      TableHeader,
    TableRow } from '@carbon/react';

import "@carbon/charts/styles.css";
import "./plex-and-carbon-components.css";
class NevaX extends React.Component {
    
    state = {
		data1: [
	{
		"group": "Dataset 1",
		"date": "2018-12-31T23:00:00.000Z",
		"value": 0
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-05T23:00:00.000Z",
		"value": 57312
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-07T23:00:00.000Z",
		"value": 21432
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-14T23:00:00.000Z",
		"value": 70323
	},
	{
		"group": "Dataset 1",
		"date": "2019-01-18T23:00:00.000Z",
		"value": 21300
	},
	{
		"group": "Dataset 2",
		"date": "2018-12-31T23:00:00.000Z",
		"value": 50000
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-04T23:00:00.000Z",
		"value": 15000
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-07T23:00:00.000Z",
		"value": 20000
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-12T23:00:00.000Z",
		"value": 39213
	},
	{
		"group": "Dataset 2",
		"date": "2019-01-18T23:00:00.000Z",
		"value": 61213
	},
	{
		"group": "Dataset 3",
		"date": "2019-01-01T23:00:00.000Z",
		"value": 10
	},
	{
		"group": "Dataset 3",
		"date": "2019-01-05T23:00:00.000Z",
		"value": 37312
	},
	{
		"group": "Dataset 3",
		"date": "2019-01-07T23:00:00.000Z",
		"value": 51432
	},
	{
		"group": "Dataset 3",
		"date": "2019-01-12T23:00:00.000Z",
		"value": 40323
	},
	{
		"group": "Dataset 3",
		"date": "2019-01-18T23:00:00.000Z",
		"value": 31300
	}
],
		options1: {
	"title": "Area (time series)",
	"axes": {
		"bottom": {
			"title": "2019 Annual Sales Figures",
			"mapsTo": "date",
			"scaleType": "time"
		},
		"left": {
			"mapsTo": "value",
			"title": "Conversion rate",
			"scaleType": "linear"
		}
	},
	"height": "400px"
},data2: [
	{
		"group": "Qty",
		"value": 65000
	},
	{
		"group": "More",
		"value": 29123
	},
	{
		"group": "Sold",
		"value": 35213
	},
	{
		"group": "Restocking",
		"value": 51213
	},
	{
		"group": "Misc",
		"value": 16932
	}
],
		options2: {
	"title": "Vertical simple bar (discrete)",
	"axes": {
		"left": {
			"mapsTo": "value"
		},
		"bottom": {
			"mapsTo": "group",
			"scaleType": "labels"
		}
	},
	"height": "400px"
},data3: [
	{
		"group": "Dataset 1",
		"age": 20
	},
	{
		"group": "Dataset 2",
		"age": 21
	},
	{
		"group": "Dataset 2",
		"age": 23
	},
	{
		"group": "Dataset 3",
		"age": 21
	},
	{
		"group": "Dataset 3",
		"age": 23
	},
	{
		"group": "Dataset 3",
		"age": 24
	},
	{
		"group": "Dataset 1",
		"age": 30
	},
	{
		"group": "Dataset 2",
		"age": 34
	},
	{
		"group": "Dataset 1",
		"age": 35
	},
	{
		"group": "Dataset 3",
		"age": 30
	},
	{
		"group": "Dataset 1",
		"age": 40
	},
	{
		"group": "Dataset 2",
		"age": 43
	},
	{
		"group": "Dataset 1",
		"age": 45
	},
	{
		"group": "Dataset 1",
		"age": 46
	},
	{
		"group": "Dataset 3",
		"age": 40
	},
	{
		"group": "Dataset 3",
		"age": 43
	},
	{
		"group": "Dataset 3",
		"age": 45
	},
	{
		"group": "Dataset 1",
		"age": 48
	},
	{
		"group": "Dataset 1",
		"age": 50
	},
	{
		"group": "Dataset 2",
		"age": 55
	},
	{
		"group": "Dataset 2",
		"age": 66
	},
	{
		"group": "Dataset 2",
		"age": 58
	},
	{
		"group": "Dataset 1",
		"age": 70
	},
	{
		"group": "Dataset 1",
		"age": 78
	},
	{
		"group": "Dataset 3",
		"age": 71
	},
	{
		"group": "Dataset 3",
		"age": 75
	},
	{
		"group": "Dataset 2",
		"age": 83
	},
	{
		"group": "Dataset 2",
		"age": 86
	},
	{
		"group": "Dataset 1",
		"age": 87
	}
],
		options3: {
	"title": "Histogram (linear)",
	"axes": {
		"bottom": {
			"title": "Age",
			"mapsTo": "age",
			"bins": 10,
			"limitDomainToBins": true
		},
		"left": {
			"title": "No. of participants",
			"scaleType": "linear",
			"stacked": true,
			"binned": true
		}
	},
	"height": "400px"
}
	};
    render = () => (
        <div className="bx--grid bx--grid--full-width">
             <div className="bx--row ">
                    <div className="bx--col-lg-12 ">
	
		
                    </div>
                    </div>
                    <div className="bx--row ">
                    <div className="bx--col-lg-4 ">

		<AreaChart
			data={this.state.data1}
			options={this.state.options1}>
		</AreaChart>
        </div>
        <div className="bx--col-lg-8 ">

        <SimpleBarChart
			data={this.state.data2}
			options={this.state.options2}>
		</SimpleBarChart>
        </div>
        </div>
        <div className="bx--row ">
                    <div className="bx--col-lg-4 ">

		<HistogramChart
			data={this.state.data3}
			options={this.state.options3}>
		</HistogramChart>
        </div>
        <div className="bx--col-lg-8 ">


        </div>
        </div>
       
        </div>
	);
};

export default NevaX;