import React from "react";
import "@carbon/charts/styles.css";
import ROSLIB from "roslib"
import { Button,TextInput,CodeSnippet,Tile, Tabs,Tab,TabList,TabPanel,TabPanels } from "@carbon/react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import {GiTomato} from "react-icons/gi"
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from '@carbon/react';

const res_tom=[];
const ipRobot = "192.168.1.102"
class TeleopRos extends React.Component {

     constructor(props){
         super()
         this.state={

          
         
           mode:"free",
          layoutName: "default",
           cam_3_imagedata:"http://"+ipRobot+":8080/stream?topic=/camera1/color/image_raw&type=ros_compressed",
           cam_2_imagedata:"http://"+ipRobot+":8080/stream?topic=/camera2/color/image_raw&type=ros_compressed",
           cam_1_imagedata:"http://"+ipRobot+":8080/stream?topic=/camera3/color/image_raw&type=ros_compressed",
           yolo_imagedata:"http://"+ipRobot+":8080/stream?topic=/cam2/yolo_image_cam1&type=ros_compressed",
           TRACKF_imagedata:"http://"+ipRobot+":8080/stream?topic=/output_f/image_raw&type=ros_compressed",
           TRACKR_imagedata:"http://"+ipRobot+":8080/stream?topic=/output_r/image_raw&type=ros_compressed",

           input:null,
           inputName:"input1",
           btnDisable:true,
           default_url_cam1:"https://picsum.photos/700/300?random=1",
           default_url_cam2:"https://picsum.photos/700/300?random=2",
           default_url_cam3:"https://picsum.photos/700/300?random=3",
           goToNav:false,
           distance_travelled:0,
           time_remaining:20,
           count_red:0,
           count_green:0,
           count_orange:0,
           result_Scan:[],
           socket_address:"ws://192.168.2.120:9090"


          }
          
        
         this.ros = new ROSLIB.Ros({
            url : "ws://"+ipRobot+":9090"
          });
       

          // First, we create a Topic object with details of the topic's name and message type.
this.cmdVel = new ROSLIB.Topic({
    ros : this.ros,
    name : '/cmd_vel',
    messageType : 'geometry_msgs/Twist'
  });
this.imageTopic = new ROSLIB.Topic({
    ros : this.ros,
    name : '/camera4/color/image_raw/compressed',
    messageType : 'sensor_msgs/CompressedImage'
});
this.distanceTopic = new ROSLIB.Topic({
  ros : this.ros,
  name : '/distance_travelled',
  messageType : 'std_msgs/Float32'
});
this.detection_total = new ROSLIB.Topic({
    ros: this.ros,
    name:'/cam2/yolo_detection',
    messageType: 'std_msgs/Int32MultiArray'
})
this.detection_cam1 = new ROSLIB.Topic({
  ros: this.ros,
  name:'/cam2/yolo_detection_1',
  messageType: 'std_msgs/Int32MultiArray'
})
this.detection_cam2 = new ROSLIB.Topic({
  ros: this.ros,
  name:'/cam2/yolo_detection_2',
  messageType: 'std_msgs/Int32MultiArray'
})
this.detection_cam3 = new ROSLIB.Topic({
  ros: this.ros,
  name:'/cam2/yolo_detection_3',
  messageType: 'std_msgs/Int32MultiArray'
})
this.robotState = new ROSLIB.Topic({
  ros: this.ros,
  name:'/robo_state',
  messageType: 'std_msgs/String'
})
this.userState = new ROSLIB.Topic({
  ros: this.ros,
  name:'/user_state',
  messageType: 'std_msgs/String'
})
this.detectState = new ROSLIB.Topic({
  ros: this.ros,
  name:'/detect_state',
  messageType: 'std_msgs/String'
})
this.row_number = new ROSLIB.Topic({
  ros: this.ros,
  name:'/row_no',
  messageType: 'std_msgs/String'
})
  this.activateSpeed= this.activateSpeed.bind(this)
  this.deactivateSpeed = this.deactivateSpeed.bind(this)
  this.analyze_count_total = this.analyze_count_total.bind(this)
  this.analyze_count_cam1 = this.analyze_count_cam1.bind(this)
  this.analyze_count_cam2 = this.analyze_count_cam2.bind(this)
  this.analyze_count_cam3 = this.analyze_count_cam3.bind(this)
  this.refCam1= React.createRef();
  this.row=[]
  this.rows = [
    {
      id: 'a',
      name: '7 week ahead ',
      red: this.state.count_red_cam3,
      orange:0,
      green:0

    },
    {
      id: 'b',
      name: '4 week ahead',
      red: 0,
      orange:0,
      green:0
    },
    {
      id: 'c',
      name: '2 week ahead',
      red: 0,
      orange:0,
      green:0
    },
    {
      id: 'd',
      name: 'Total',
      red: 0,
      orange:0,
      green:0
    },
  ];
  this.headers = [
    {
      key: 'name',
      header: 'Prediction',
    },
    {
      key: 'red',
      header: 'Red',
    },
    {
      key: 'Orange',
      header: 'Orange',
    },            {
      key: 'Green',
      header: 'Green',
    },
  ];



     }
   
componentDidMount(){
   let res=0

    this.ros.on('connection', function() {
        console.log('Connected to websocket server.');
      });
    
      this.ros.on('error', function(error) {
        console.log('Error connecting to websocket server: ', error);
      });
    
      let thisRos = this.ros
      this.ros.on('close', function() {
        console.log('Connection to websocket server closed.');
        this.timerId = setInterval(() => {
          console.log('reconnecting....');

          thisRos.connect("ws://"+ipRobot+":9090")
      }, 1000);
      });

    this.cmdVel.subscribe(function(msg){
        //console.log("received message on cmd_vel topic" + msg)
    })


    var img;
    this.imageTopic.subscribe(function(msg){
        // console.log("received message on image topic" + msg)
        img="data:image/jpg;base64,"+msg.data
        //this.imageTopic = this.imageTopic.bind(this);

    })
    
    let self=this    

    this.detection_total.subscribe((msg)=>
      {
      console.log("Detection_total", msg.data)
      self.setState({result_Scan_total:msg.data})  
      //console.log("Detection", self.state.result_Scan)
      self.analyze_count_total()
    
    })
    this.detection_cam1.subscribe((msg)=>
      {
      console.log("Detection_cam1", msg.data)
      self.setState({result_Scan_cam1:msg.data})  
      //console.log("Detection", self.state.result_Scan)
      self.analyze_count_cam1()
    
    })
    this.detection_cam2.subscribe((msg)=>
      {
      console.log("Detection_cam2", msg.data)
      self.setState({result_Scan_cam2:msg.data})  
      //console.log("Detection", self.state.result_Scan)
      self.analyze_count_cam2()
    
    })
    this.detection_cam3.subscribe((msg)=>
      {
      console.log("Detection_cam3", msg.data)
      self.setState({result_Scan_cam3:msg.data})  
      //console.log("Detection", self.state.result_Scan)
      self.analyze_count_cam3()
    
    })
    this.scanRequest = new ROSLIB.Topic({
      ros : this.ros,
      name : '/scan/request',
      messageType : 'geometry_msgs/Bool'
    });
    
    this.robotState.subscribe((msg) => {
    
        console.log("msg robot",msg.data) 
        self.setState({robotStatus:msg.data})
    })
    this.userState.subscribe((msg) => {
    
      //console.log("msg robot",msg.data) 
      self.setState({userStatus:msg.data})
  })
  this.detectState.subscribe((msg) => {
    
    //console.log("msg robot",msg.data) 
    self.setState({detectStatus:msg.data})
})
    this.distanceTopic.subscribe((msg) => {
      let v = msg.data
      self.setState({distance_travelled:v})
      console.log("distance travel", v)
    })
}

    analyze_count_total(){
     
       
        this.setState({count_green_total:Math.round(this.state.result_Scan_total[2])})
     
        this.setState({count_orange_total:Math.round(this.state.result_Scan_total[1])})

        
          this.setState({count_red_total:Math.round(this.state.result_Scan_total[0])})
          // console.log("STate count", this.state.count_red)
         
        
   
    }

    analyze_count_cam1(){
     
       
      this.setState({count_green_cam1:Math.round(this.state.result_Scan_cam1[2])})
   
      this.setState({count_orange_cam1:Math.round(this.state.result_Scan_cam1[1])})

      
        this.setState({count_red_cam1:Math.round(this.state.result_Scan_cam1[0])})
        // console.log("STate count", this.state.count_red)
       
      
 
  }
  analyze_count_cam2(){
     
       
    this.setState({count_green_cam2:Math.round(this.state.result_Scan_cam2[2])})
 
    this.setState({count_orange_cam2:Math.round(this.state.result_Scan_cam2[1])})

    
      this.setState({count_red_cam2:Math.round(this.state.result_Scan_cam2[0])})
      // console.log("STate count", this.state.count_red)
     
    

}
analyze_count_cam3(){
      
  this.setState({count_green_cam3:Math.round(this.state.result_Scan_cam3[2])})

  this.setState({count_orange_cam3:Math.round(this.state.result_Scan_cam3[1])})

  
    this.setState({count_red_cam3:Math.round(this.state.result_Scan_cam3[0])})

    

}
    activateSpeed(){
  this.setState({
    time_remaining:20,
           count_red:0,
           count_green:0,
           count_orange:0,
           result_Scan:[]
  })
        // Then we create the payload to be published. The object we pass in to ros.Message matches the
  // fields defined in the geometry_msgs/Twist.msg definition.
  var twist = new ROSLIB.Message({
    linear : {
      x : 0.5,
      y : 0,
      z : 0
    },
    angular : {
      x : -0.0,
      y : -0.0,
      z : -0.0
    }
  });

  var twist2 = new ROSLIB.Message({
    linear : {
      x : 3.0,
      y : 0,
      z : 0
    },
    angular : {
      x : -0.0,
      y : -0.0,
      z : -0.0
    }
  });
  // And finally, publish.
  var msg=new ROSLIB.Message({data:true})
  this.scanRequest.publish(msg) 
  console.log("Publish request scan ", msg)
  var msg_row=new ROSLIB.Message({data:this.state.input})

  this.row_number.publish(msg_row)
 
  //this.cmdVel.publish(twist);
  //console.log("Publish cmd vel ", twist)
  this.setState({goToNav:true})

  //this.cmdVel.publish(twist2);

        //console.log('i clicked')
    }

    deactivateSpeed(){
      this.setState({
        time_remaining:20,
               count_red:0,
               count_green:0,
               count_orange:0,
               result_Scan:[],
               distance_travelled:0
      })
             // Then we create the payload to be published. The object we pass in to ros.Message matches the
  // fields defined in the geometry_msgs/Twist.msg definition.
  var twist = new ROSLIB.Message({
    linear : {
      x : 0,
      y : 0,
      z : 0
    },
    angular : {
      x : -0.0,
      y : -0.0,
      z : -0.0
    }
  });
  //this.cmdVel.publish(twist);
  var msg=new ROSLIB.Message({data:false})
  this.scanRequest.publish(msg)
  this.setState({input:""})
  this.setState({goToNav:false})


    }
    onChangeAll = (inputObj) => {
      // this.setState({
      //   input: inputObj
      // });
      // console.log("Input state", this.state.input);

      //console.log("Input changed all", this.state.input.input1);
      //console.log("Input length", this.state.input.input1.length);
//     if(this.state.input.input1.length > 0){
// this.state.btnDisable=false
//     }
//     this.state.btnDisable=true
    
      
    };
  
    onChange = inputobj => {
      console.log("ONCHANGE: Input changed", inputobj);
      this.setState({
        input: inputobj
      });
      
      if(this.state.input.length>0){
        this.state.btnDisable=false
        console.log("STATE LENGTH INPUT", this.state.input.length)

      }
      if(this.state.input.length===1){
        console.log("STATE LENGTH INPUT", this.state.input.length)
        this.state.btnDisable=true}

        //this.refCam1.current.src="https://picsum.photos/id/237/200/300"

    };
  
    onKeyPress = button => {
      console.log("Button pressed", button);
      console.log("ONCHANGE: Input CURRENT", this.state.input);
      

      /**
       * If you want to handle the shift and caps lock buttons
       */
      //if (button === "{shift}" || button === "{lock}") this.handleShift();
    };
  
    handleShift = () => {
      let layoutName = this.state.layoutName;
  
      this.setState({
        layoutName: layoutName === "default" ? "shift" : "default"
      });
    };
  
    onChangeInput = event => {
      let input = event.target.value;
      this.setState(
        {
          input: input
        },
        () => {
          this.keyboard.setInput(input);
        }
      );
    };
    render () {
        
      //console.log("CAM1",this.state.cam_1_imagedata)
   
          
        return (
          <div className="bx--grid bx--grid--full-width" >
                         <div className="bx--row row-no1 ">
                       {!this.state.goToNav }  
                      {this.state.goToNav && (
     
                       <> </> 
                      )}  
   </div>
            <div className="bx--row row-no2">
            <div className="bx--col-lg-6 " 
                    >
             <Tabs>
              <TabList>
                <Tab>Live Camera</Tab>
                <Tab>AI Detection</Tab>
                <Tab>Rail</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                <img className="CAM3" ref= {this.refCam3 } src={this.state.cam_1_imagedata}  width="400" heigh="300"/>
             <img className="CAM2"  ref= {this.refCam2 } src={this.state.cam_2_imagedata}  width="400" heigh="300"/>
             <img className="CAM1"  ref= {this.refCam1 } src={this.state.cam_3_imagedata}  width="400" heigh="300"/>
                </TabPanel>
                <TabPanel>
                {/* <img className="CAM3" ref= {this.refCam1 } src={this.state.TRACKF_imagedata}  width="600" heigh="300"/> */}
             {/* <img className="CAM2"  ref= {this.refCam2 } src={this.state.TRACKR_imagedata}  width="600" heigh="300"/> */}
            
             <img className="CAM1"  ref= {this.refCam3 } src={this.state.yolo_imagedata}  width="500" heigh="400"/>
             <section class="bx--structured-list">
  <div class="bx--structured-list-thead">
    <div class="bx--structured-list-row bx--structured-list-row--header-row">
      <div class="bx--structured-list-th">Prediction</div>
      <div class="bx--structured-list-th">Red  <GiTomato style={{color:"red"}}></GiTomato></div>
      <div class="bx--structured-list-th">Orange  <GiTomato style={{color:"orange"}}></GiTomato></div>
      <div class="bx--structured-list-th">Green  <GiTomato style={{color:"green"}}></GiTomato></div>
    </div>
  </div>
  <div class="bx--structured-list-tbody">
    <div class="bx--structured-list-row" style={{textAlign:"left"}}>
      <div class="bx--structured-list-td bx--structured-list-content--nowrap">7 weeks ahead</div>
      <div class="bx--structured-list-td">{this.state.count_red_cam3}</div>
      <div class="bx--structured-list-td">
      {this.state.count_orange_cam3}
      </div>
      <div class="bx--structured-list-td">
      {this.state.count_green_cam3}
      </div>
    </div>
    <div class="bx--structured-list-row" style={{textAlign:"left"}}>
    <div class="bx--structured-list-td bx--structured-list-content--nowrap">4 weeks ahead</div>
      <div class="bx--structured-list-td">{this.state.count_red_cam2}</div>
      <div class="bx--structured-list-td">
      {this.state.count_orange_cam2}
      </div>
      <div class="bx--structured-list-td">
      {this.state.count_green_cam2}
      </div>
    </div>
    <div class="bx--structured-list-row" style={{textAlign:"left"}}>
    <div class="bx--structured-list-td bx--structured-list-content--nowrap">2 weeks ahead</div>
      <div class="bx--structured-list-td">{this.state.count_red_cam1}</div>
      <div class="bx--structured-list-td">
      {this.state.count_orange_cam1}
      </div>
      <div class="bx--structured-list-td">
      {this.state.count_green_cam1}
      </div>
    </div>
    <div class="bx--structured-list-row" style={{textAlign:"left"}}>
    <div class="bx--structured-list-td bx--structured-list-content--nowrap"><strong>Total</strong></div>
      <div class="bx--structured-list-td"><strong>{this.state.count_red_total}</strong></div>
      <div class="bx--structured-list-td">
      <strong>{this.state.count_orange_total}</strong>
      </div>
      <div class="bx--structured-list-td">
      <strong>{this.state.count_green_total}</strong>
      </div>
    </div>
  </div>
</section>
             {/* <DataTable rows={this.rows} headers={this.headers}>
  {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
    <Table {...getTableProps()}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableHeader {...getHeaderProps({ header })}>
              {header.header}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow {...getRowProps({ row })}>
            {row.cells.map((cell) => (
              <TableCell key={cell.id}>{cell.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )}
</DataTable> */}
             {/* <><div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"red"}}></GiTomato></h2> <h1>{this.state.count_red_total}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2>  <GiTomato style={{color:"green"}}></GiTomato></h2> <h1>{this.state.count_green_total}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"orange"}}></GiTomato></h2> <h1>{this.state.count_orange_total}</h1> </Tile>
     </div>
     </>
     <><div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"red"}}></GiTomato></h2> <h1>{this.state.count_red_cam3}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2>  <GiTomato style={{color:"green"}}></GiTomato></h2> <h1>{this.state.count_green_cam3}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"orange"}}></GiTomato></h2> <h1>{this.state.count_orange_cam3}</h1> </Tile>
     </div>
     </>
     <><div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"red"}}></GiTomato></h2> <h1>{this.state.count_red_cam2}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2>  <GiTomato style={{color:"green"}}></GiTomato></h2> <h1>{this.state.count_green_cam2}</h1></Tile>
     </div>
     <div className="bx--col-lg-1"> <Tile><h2> <GiTomato style={{color:"orange"}}></GiTomato></h2> <h1>{this.state.count_orange_cam2}</h1> </Tile>
     </div>
     </> */}
                </TabPanel>
                <TabPanel>
                <img className="CAM3" ref= {this.refCam1 } src={this.state.TRACKF_imagedata}  width="400" heigh="300"/>
             <img className="CAM2"  ref= {this.refCam2 } src={this.state.TRACKR_imagedata}  width="400" heigh="300"/>
             {/* <img className="CAM1"  ref= {this.refCam3 } src={this.state.yolo_imagedata}  width="600" heigh="300"/> */}
                </TabPanel>
              </TabPanels>
              </Tabs>         
             {/* <h5>live stream</h5> */}
             {/* <img className="CAM3" ref= {this.refCam1 } src={this.state.TRACKF_imagedata}  width="600" heigh="300"/>
             <img className="CAM2"  ref= {this.refCam2 } src={this.state.TRACKR_imagedata}  width="600" heigh="300"/>
             <img className="CAM1"  ref= {this.refCam3 } src={this.state.yolo_imagedata}  width="600" heigh="300"/> */}


         
                    </div>
            {  !this.state.goToNav &&  (<div className="bx--col-lg-4 header" 
>
             <h1 style={{fontWeight:"bold"}}>Enter Row Number</h1>  <br></br><br></br>
            <Tile> <div style={{minHeight:"100px"}}><h1 className="inputNumber">{this.state.input}</h1></div></Tile>
             <Keyboard
          keyboardRef={(r) => (this.keyboard = r)}
          inputName={this.state.inputName}
          layoutName={this.state.layoutName}
          layout={{
            default: ["1 2 3", "4 5 6", "7 8 9", "{//} 0 {//}", "{bksp}"],
            shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
          }}
          onChange={(inputObj) => this.onChange(inputObj)}
          onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
          onKeyPress={(button) => this.onKeyPress(button)}
          theme={"hg-theme-default hg-layout-numeric numeric-theme"}
        />
            {(<Button
            className="startScanBtn"
            kind="primary"
            onClick={this.activateSpeed}
            disabled={this.state.btnDisable}
            >START SCAN</Button>)}

             </div>  ) }

             {  this.state.goToNav &&  (<div className="bx--col-lg-4 header" 
>
             <h1 >Scanning Row: </h1>  <br/>
             <div style={{minHeight:"100px"}}><h1 className="inputNumber">{this.state.input}</h1></div>
             <Tile>
             <h1 >Distance Travelled: </h1>  <br/>
             <div ><h3 className="inputNumber">{this.state.distance_travelled.toFixed(2)+" meters"}</h3></div>
             {/* <h1 >Time remaining: </h1>  <br/> */}
             {/* <div ><h1 className="inputNumber">{this.state.time_remaining+" minutes"}</h1></div> */}
            
             </Tile>
             <Tile>
             <h1 >STATUS_ROBOT: </h1>  <br/>
             <div ><h3 className="inputNumber">{this.state.robotStatus}</h3></div>
             {/* <h1 >STATUS_USER: </h1>  <br/>
             <div ><h3 className="inputNumber">{this.state.userStatus}</h3></div> */}
             {/* <h1 >STATUS_DETECT: </h1>  <br/> */}
             {/* <div ><h3 className="inputNumber">{this.state.robotStatus}</h3></div> */}
             {/* <h1 >Time remaining: </h1>  <br/> */}
             {/* <div ><h1 className="inputNumber">{this.state.time_remaining+" minutes"}</h1></div> */}
            
             </Tile>
            {(<Button
            className="cancelScanBtn"
            kind="danger"
            onClick={this.deactivateSpeed}
            >Cancel SCAN</Button>)}

             </div>  ) }

                   
                    
             </div>
             {/* <div className="bx--row row-no3"> </div> */}
          </div>
      
            )

    }

    
}

export default TeleopRos;
