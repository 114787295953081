import React from "react";
import { AreaChart,SimpleBarChart,HistogramChart,HeatmapChart,ComboChart } from "@carbon/charts-react";
import { DataTable,Table,
      TableBody,
      TableCell,
      TableContainer,
      TableHead,
      TableHeader,
    TableRow } from '@carbon/react';
import { Dropdown } from "carbon-components-react";
import "@carbon/charts/styles.css";
import "./plex-and-carbon-components.css";
import RipingImage from '../../assets/images/riping.jpg'
import DiameterImage from '../../assets/images/diameter.jpg'
import CountingImage from '../../assets/images/counter.jpg'
import { getDatabase,ref, onValue,get,child } from "firebase/database";
import { dbRef } from "../../firebase/firebase";

class HarvestPredictionRow extends React.Component {
    
    state = {
		dataPercentage: [
			{
			  'meter': '0-4',
			  'hoogte': '0-1 wk',
			  'value': 83
			},
			{
			  'meter': '0-4',
			  'hoogte': '1-2 wk ',
			  'value': 63
			},
			{
			  'meter': '0-4',
			  'hoogte': '2-3 wk',
			  'value': 37
			},
			{
			  'meter': '0-4',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '0-4',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '0-4',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '0-4',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '4-8',
			  'hoogte': '0-1 wk',
			  'value': 85
			},
			{
			  'meter': '4-8',
			  'hoogte': '1-2 wk ',
			  'value': 74
			},
			{
			  'meter': '4-8',
			  'hoogte': '2-3 wk',
			  'value': 32
			},
			{
			  'meter': '4-8',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '4-8',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '4-8',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '4-8',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '8-12',
			  'hoogte': '0-1 wk',
			  'value': 83
			},
			{
			  'meter': '8-12',
			  'hoogte': '1-2 wk ',
			  'value': 61
			},
			{
			  'meter': '8-12',
			  'hoogte': '2-3 wk',
			  'value': 32
			},
			{
			  'meter': '8-12',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '8-12',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '8-12',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '8-12',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '12-16',
			  'hoogte': '0-1 wk',
			  'value': 77
			},
			{
			  'meter': '12-16',
			  'hoogte': '1-2 wk ',
			  'value': 79
			},
			{
			  'meter': '12-16',
			  'hoogte': '2-3 wk',
			  'value': 35
			},
			{
			  'meter': '12-16',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '12-16',
			  'hoogte': '4-5 wk',
			  'value': 13
			},
			{
			  'meter': '12-16',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '12-16',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '16-20',
			  'hoogte': '0-1 wk',
			  'value': 79
			},
			{
			  'meter': '16-20',
			  'hoogte': '1-2 wk ',
			  'value': 63
			},
			{
			  'meter': '16-20',
			  'hoogte': '2-3 wk',
			  'value': 30
			},
			{
			  'meter': '16-20',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '16-20',
			  'hoogte': '4-5 wk',
			  'value': 15
			},
			{
			  'meter': '16-20',
			  'hoogte': '5-6 wk',
			  'value': 13
			},
			{
			  'meter': '16-20',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '20-24',
			  'hoogte': '0-1 wk',
			  'value': 82
			},
			{
			  'meter': '20-24',
			  'hoogte': '1-2 wk ',
			  'value': 69
			},
			{
			  'meter': '20-24',
			  'hoogte': '2-3 wk',
			  'value': 31
			},
			{
			  'meter': '20-24',
			  'hoogte': '3-4 wk',
			  'value': 25
			},
			{
			  'meter': '20-24',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '20-24',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '20-24',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '24-28',
			  'hoogte': '0-1 wk',
			  'value': 81
			},
			{
			  'meter': '24-28',
			  'hoogte': '1-2 wk ',
			  'value': 66
			},
			{
			  'meter': '24-28',
			  'hoogte': '2-3 wk',
			  'value': 37
			},
			{
			  'meter': '24-28',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '24-28',
			  'hoogte': '4-5 wk',
			  'value': 15
			},
			{
			  'meter': '24-28',
			  'hoogte': '5-6 wk',
			  'value': 13
			},
			{
			  'meter': '24-28',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '28-32',
			  'hoogte': '0-1 wk',
			  'value': 88
			},
			{
			  'meter': '28-32',
			  'hoogte': '1-2 wk ',
			  'value': 70
			},
			{
			  'meter': '28-32',
			  'hoogte': '2-3 wk',
			  'value': 35
			},
			{
			  'meter': '28-32',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '28-32',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '28-32',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '28-32',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '32-36',
			  'hoogte': '0-1 wk',
			  'value': 85
			},
			{
			  'meter': '32-36',
			  'hoogte': '1-2 wk ',
			  'value': 73
			},
			{
			  'meter': '32-36',
			  'hoogte': '2-3 wk',
			  'value': 34
			},
			{
			  'meter': '32-36',
			  'hoogte': '3-4 wk',
			  'value': 22
			},
			{
			  'meter': '32-36',
			  'hoogte': '4-5 wk',
			  'value': 13
			},
			{
			  'meter': '32-36',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '32-36',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '36-40',
			  'hoogte': '0-1 wk',
			  'value': 86
			},
			{
			  'meter': '36-40',
			  'hoogte': '1-2 wk ',
			  'value': 69
			},
			{
			  'meter': '36-40',
			  'hoogte': '2-3 wk',
			  'value': 31
			},
			{
			  'meter': '36-40',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '36-40',
			  'hoogte': '4-5 wk',
			  'value': 13
			},
			{
			  'meter': '36-40',
			  'hoogte': '5-6 wk',
			  'value': 9
			},
			{
			  'meter': '36-40',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '40-44',
			  'hoogte': '0-1 wk',
			  'value': 82
			},
			{
			  'meter': '40-44',
			  'hoogte': '1-2 wk ',
			  'value': 78
			},
			{
			  'meter': '40-44',
			  'hoogte': '2-3 wk',
			  'value': 32
			},
			{
			  'meter': '40-44',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '40-44',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '40-44',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '40-44',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '44-48',
			  'hoogte': '0-1 wk',
			  'value': 79
			},
			{
			  'meter': '44-48',
			  'hoogte': '1-2 wk ',
			  'value': 62
			},
			{
			  'meter': '44-48',
			  'hoogte': '2-3 wk',
			  'value': 35
			},
			{
			  'meter': '44-48',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '44-48',
			  'hoogte': '4-5 wk',
			  'value': 10
			},
			{
			  'meter': '44-48',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '44-48',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '48-52',
			  'hoogte': '0-1 wk',
			  'value': 82
			},
			{
			  'meter': '48-52',
			  'hoogte': '1-2 wk ',
			  'value': 66
			},
			{
			  'meter': '48-52',
			  'hoogte': '2-3 wk',
			  'value': 37
			},
			{
			  'meter': '48-52',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '48-52',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '48-52',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '48-52',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '52-56',
			  'hoogte': '0-1 wk',
			  'value': 86
			},
			{
			  'meter': '52-56',
			  'hoogte': '1-2 wk ',
			  'value': 60
			},
			{
			  'meter': '52-56',
			  'hoogte': '2-3 wk',
			  'value': 38
			},
			{
			  'meter': '52-56',
			  'hoogte': '3-4 wk',
			  'value': 22
			},
			{
			  'meter': '52-56',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '52-56',
			  'hoogte': '5-6 wk',
			  'value': 9
			},
			{
			  'meter': '52-56',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '56-60',
			  'hoogte': '0-1 wk',
			  'value': 81
			},
			{
			  'meter': '56-60',
			  'hoogte': '1-2 wk ',
			  'value': 65
			},
			{
			  'meter': '56-60',
			  'hoogte': '2-3 wk',
			  'value': 36
			},
			{
			  'meter': '56-60',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '56-60',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '56-60',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '56-60',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '60-64',
			  'hoogte': '0-1 wk',
			  'value': 86
			},
			{
			  'meter': '60-64',
			  'hoogte': '1-2 wk ',
			  'value': 64
			},
			{
			  'meter': '60-64',
			  'hoogte': '2-3 wk',
			  'value': 36
			},
			{
			  'meter': '60-64',
			  'hoogte': '3-4 wk',
			  'value': 22
			},
			{
			  'meter': '60-64',
			  'hoogte': '4-5 wk',
			  'value': 13
			},
			{
			  'meter': '60-64',
			  'hoogte': '5-6 wk',
			  'value': 9
			},
			{
			  'meter': '60-64',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '64-68',
			  'hoogte': '0-1 wk',
			  'value': 84
			},
			{
			  'meter': '64-68',
			  'hoogte': '1-2 wk ',
			  'value': 72
			},
			{
			  'meter': '64-68',
			  'hoogte': '2-3 wk',
			  'value': 32
			},
			{
			  'meter': '64-68',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '64-68',
			  'hoogte': '4-5 wk',
			  'value': 14
			},
			{
			  'meter': '64-68',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '64-68',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '68-72',
			  'hoogte': '0-1 wk',
			  'value': 87
			},
			{
			  'meter': '68-72',
			  'hoogte': '1-2 wk ',
			  'value': 80
			},
			{
			  'meter': '68-72',
			  'hoogte': '2-3 wk',
			  'value': 34
			},
			{
			  'meter': '68-72',
			  'hoogte': '3-4 wk',
			  'value': 25
			},
			{
			  'meter': '68-72',
			  'hoogte': '4-5 wk',
			  'value': 13
			},
			{
			  'meter': '68-72',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '68-72',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '72-76',
			  'hoogte': '0-1 wk',
			  'value': 89
			},
			{
			  'meter': '72-76',
			  'hoogte': '1-2 wk ',
			  'value': 61
			},
			{
			  'meter': '72-76',
			  'hoogte': '2-3 wk',
			  'value': 34
			},
			{
			  'meter': '72-76',
			  'hoogte': '3-4 wk',
			  'value': 22
			},
			{
			  'meter': '72-76',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '72-76',
			  'hoogte': '5-6 wk',
			  'value': 9
			},
			{
			  'meter': '72-76',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '76-80',
			  'hoogte': '0-1 wk',
			  'value': 87
			},
			{
			  'meter': '76-80',
			  'hoogte': '1-2 wk ',
			  'value': 75
			},
			{
			  'meter': '76-80',
			  'hoogte': '2-3 wk',
			  'value': 31
			},
			{
			  'meter': '76-80',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '76-80',
			  'hoogte': '4-5 wk',
			  'value': 15
			},
			{
			  'meter': '76-80',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '76-80',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '80-84',
			  'hoogte': '0-1 wk',
			  'value': 82
			},
			{
			  'meter': '80-84',
			  'hoogte': '1-2 wk ',
			  'value': 73
			},
			{
			  'meter': '80-84',
			  'hoogte': '2-3 wk',
			  'value': 35
			},
			{
			  'meter': '80-84',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '80-84',
			  'hoogte': '4-5 wk',
			  'value': 14
			},
			{
			  'meter': '80-84',
			  'hoogte': '5-6 wk',
			  'value': 8
			},
			{
			  'meter': '80-84',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '84-88',
			  'hoogte': '0-1 wk',
			  'value': 85
			},
			{
			  'meter': '84-88',
			  'hoogte': '1-2 wk ',
			  'value': 69
			},
			{
			  'meter': '84-88',
			  'hoogte': '2-3 wk',
			  'value': 37
			},
			{
			  'meter': '84-88',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '84-88',
			  'hoogte': '4-5 wk',
			  'value': 15
			},
			{
			  'meter': '84-88',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '84-88',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '88-92',
			  'hoogte': '0-1 wk',
			  'value': 79
			},
			{
			  'meter': '88-92',
			  'hoogte': '1-2 wk ',
			  'value': 77
			},
			{
			  'meter': '88-92',
			  'hoogte': '2-3 wk',
			  'value': 33
			},
			{
			  'meter': '88-92',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '88-92',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '88-92',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '88-92',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '92-96',
			  'hoogte': '0-1 wk',
			  'value': 78
			},
			{
			  'meter': '92-96',
			  'hoogte': '1-2 wk ',
			  'value': 71
			},
			{
			  'meter': '92-96',
			  'hoogte': '2-3 wk',
			  'value': 34
			},
			{
			  'meter': '92-96',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '92-96',
			  'hoogte': '4-5 wk',
			  'value': 14
			},
			{
			  'meter': '92-96',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '92-96',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '96-100',
			  'hoogte': '0-1 wk',
			  'value': 86
			},
			{
			  'meter': '96-100',
			  'hoogte': '1-2 wk ',
			  'value': 74
			},
			{
			  'meter': '96-100',
			  'hoogte': '2-3 wk',
			  'value': 35
			},
			{
			  'meter': '96-100',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '96-100',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '96-100',
			  'hoogte': '5-6 wk',
			  'value': 12
			},
			{
			  'meter': '96-100',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '100-104',
			  'hoogte': '0-1 wk',
			  'value': 80
			},
			{
			  'meter': '100-104',
			  'hoogte': '1-2 wk ',
			  'value': 69
			},
			{
			  'meter': '100-104',
			  'hoogte': '2-3 wk',
			  'value': 36
			},
			{
			  'meter': '100-104',
			  'hoogte': '3-4 wk',
			  'value': 24
			},
			{
			  'meter': '100-104',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '100-104',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '100-104',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '104-108',
			  'hoogte': '0-1 wk',
			  'value': 82
			},
			{
			  'meter': '104-108',
			  'hoogte': '1-2 wk ',
			  'value': 69
			},
			{
			  'meter': '104-108',
			  'hoogte': '2-3 wk',
			  'value': 33
			},
			{
			  'meter': '104-108',
			  'hoogte': '3-4 wk',
			  'value': 25
			},
			{
			  'meter': '104-108',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '104-108',
			  'hoogte': '5-6 wk',
			  'value': 10
			},
			{
			  'meter': '104-108',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '108-112',
			  'hoogte': '0-1 wk',
			  'value': 79
			},
			{
			  'meter': '108-112',
			  'hoogte': '1-2 wk ',
			  'value': 63
			},
			{
			  'meter': '108-112',
			  'hoogte': '2-3 wk',
			  'value': 38
			},
			{
			  'meter': '108-112',
			  'hoogte': '3-4 wk',
			  'value': 23
			},
			{
			  'meter': '108-112',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '108-112',
			  'hoogte': '5-6 wk',
			  'value': 11
			},
			{
			  'meter': '108-112',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '112-116',
			  'hoogte': '0-1 wk',
			  'value': 83
			},
			{
			  'meter': '112-116',
			  'hoogte': '1-2 wk ',
			  'value': 64
			},
			{
			  'meter': '112-116',
			  'hoogte': '2-3 wk',
			  'value': 30
			},
			{
			  'meter': '112-116',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '112-116',
			  'hoogte': '4-5 wk',
			  'value': 12
			},
			{
			  'meter': '112-116',
			  'hoogte': '5-6 wk',
			  'value': 13
			},
			{
			  'meter': '112-116',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
			{
			  'meter': '116-120',
			  'hoogte': '0-1 wk',
			  'value': 87
			},
			{
			  'meter': '116-120',
			  'hoogte': '1-2 wk ',
			  'value': 71
			},
			{
			  'meter': '116-120',
			  'hoogte': '2-3 wk',
			  'value': 39
			},
			{
			  'meter': '116-120',
			  'hoogte': '3-4 wk',
			  'value': 21
			},
			{
			  'meter': '116-120',
			  'hoogte': '4-5 wk',
			  'value': 11
			},
			{
			  'meter': '116-120',
			  'hoogte': '5-6 wk',
			  'value': 8
			},
			{
			  'meter': '116-120',
			  'hoogte': '> 7 wk',
			  'value': 0
			},
		]
		
		,
		optionsPercentage: {
	"title": "doorkleuring tomaten gereed voor oogst (groen = 0%, rood = 100%)",
	"axes": {
		"bottom": {
			"title": "Afgelegde afstand in pad (meter)",
			"mapsTo": "meter",
			"scaleType": "labels",
			"ticks": {
				"rotation": "always"}
		},
		"left": {
			"title": "Afstand tot oogstmoment",
			"mapsTo": "hoogte",
			"scaleType": "labels"
		}
	},
	"heatmap": {
		"colorLegend": {
			"title": "% doorkleuring tomaten"
		}
	},
     "color": {
	"gradient": {"enabled":true, "colors":['#69B34C', '#ACB334','#FAB733','#FF8E15','#FF4E11','#FF0D0D']}

},
	"experimental": true,
	"height": "400px"
}, dataDiameter:
[
    {
      'meter': '0-4',
      'hoogte': '0-1 wk',
      'value': 30
    },
    {
      'meter': '0-4',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '0-4',
      'hoogte': '2-3 wk',
      'value': 29
    },
    {
      'meter': '0-4',
      'hoogte': '3-4 wk',
      'value': 24
    },
    {
      'meter': '0-4',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '0-4',
      'hoogte': '5-6 wk',
      'value': 18
    },
    {
      'meter': '0-4',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '4-8',
      'hoogte': '0-1 wk',
      'value': 30
    },
    {
      'meter': '4-8',
      'hoogte': '1-2 wk ',
      'value': 29
    },
    {
      'meter': '4-8',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '4-8',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '4-8',
      'hoogte': '4-5 wk',
      'value': 22
    },
    {
      'meter': '4-8',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '4-8',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '8-12',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '8-12',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '8-12',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '8-12',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '8-12',
      'hoogte': '4-5 wk',
      'value': 23
    },
    {
      'meter': '8-12',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '8-12',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '12-16',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '12-16',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '12-16',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '12-16',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '12-16',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '12-16',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '12-16',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '16-20',
      'hoogte': '0-1 wk',
      'value': 35
    },
    {
      'meter': '16-20',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '16-20',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '16-20',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '16-20',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '16-20',
      'hoogte': '5-6 wk',
      'value': 18
    },
    {
      'meter': '16-20',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '20-24',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '20-24',
      'hoogte': '1-2 wk ',
      'value': 32
    },
    {
      'meter': '20-24',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '20-24',
      'hoogte': '3-4 wk',
      'value': 24
    },
    {
      'meter': '20-24',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '20-24',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '20-24',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '24-28',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '24-28',
      'hoogte': '1-2 wk ',
      'value': 28
    },
    {
      'meter': '24-28',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '24-28',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '24-28',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '24-28',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '24-28',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '28-32',
      'hoogte': '0-1 wk',
      'value': 34
    },
    {
      'meter': '28-32',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '28-32',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '28-32',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '28-32',
      'hoogte': '4-5 wk',
      'value': 23
    },
    {
      'meter': '28-32',
      'hoogte': '5-6 wk',
      'value': 18
    },
    {
      'meter': '28-32',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '32-36',
      'hoogte': '0-1 wk',
      'value': 30
    },
    {
      'meter': '32-36',
      'hoogte': '1-2 wk ',
      'value': 28
    },
    {
      'meter': '32-36',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '32-36',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '32-36',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '32-36',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '32-36',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '36-40',
      'hoogte': '0-1 wk',
      'value': 32
    },
    {
      'meter': '36-40',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '36-40',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '36-40',
      'hoogte': '3-4 wk',
      'value': 24
    },
    {
      'meter': '36-40',
      'hoogte': '4-5 wk',
      'value': 23
    },
    {
      'meter': '36-40',
      'hoogte': '5-6 wk',
      'value': 18
    },
    {
      'meter': '36-40',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '40-44',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '40-44',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '40-44',
      'hoogte': '2-3 wk',
      'value': 25
    },
    {
      'meter': '40-44',
      'hoogte': '3-4 wk',
      'value': 24
    },
    {
      'meter': '40-44',
      'hoogte': '4-5 wk',
      'value': 22
    },
    {
      'meter': '40-44',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '40-44',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '44-48',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '44-48',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '44-48',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '44-48',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '44-48',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '44-48',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '44-48',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '48-52',
      'hoogte': '0-1 wk',
      'value': 30
    },
    {
      'meter': '48-52',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '48-52',
      'hoogte': '2-3 wk',
      'value': 29
    },
    {
      'meter': '48-52',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '48-52',
      'hoogte': '4-5 wk',
      'value': 22
    },
    {
      'meter': '48-52',
      'hoogte': '5-6 wk',
      'value': 18
    },
    {
      'meter': '48-52',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '52-56',
      'hoogte': '0-1 wk',
      'value': 35
    },
    {
      'meter': '52-56',
      'hoogte': '1-2 wk ',
      'value': 29
    },
    {
      'meter': '52-56',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '52-56',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '52-56',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '52-56',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '52-56',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '56-60',
      'hoogte': '0-1 wk',
      'value': 35
    },
    {
      'meter': '56-60',
      'hoogte': '1-2 wk ',
      'value': 31
    },
    {
      'meter': '56-60',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '56-60',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '56-60',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '56-60',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '56-60',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '60-64',
      'hoogte': '0-1 wk',
      'value': 34
    },
    {
      'meter': '60-64',
      'hoogte': '1-2 wk ',
      'value': 32
    },
    {
      'meter': '60-64',
      'hoogte': '2-3 wk',
      'value': 29
    },
    {
      'meter': '60-64',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '60-64',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '60-64',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '60-64',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '64-68',
      'hoogte': '0-1 wk',
      'value': 32
    },
    {
      'meter': '64-68',
      'hoogte': '1-2 wk ',
      'value': 32
    },
    {
      'meter': '64-68',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '64-68',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '64-68',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '64-68',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '64-68',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '68-72',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '68-72',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '68-72',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '68-72',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '68-72',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '68-72',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '68-72',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '72-76',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '72-76',
      'hoogte': '1-2 wk ',
      'value': 28
    },
    {
      'meter': '72-76',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '72-76',
      'hoogte': '3-4 wk',
      'value': 24
    },
    {
      'meter': '72-76',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '72-76',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '72-76',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '76-80',
      'hoogte': '0-1 wk',
      'value': 34
    },
    {
      'meter': '76-80',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '76-80',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '76-80',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '76-80',
      'hoogte': '4-5 wk',
      'value': 22
    },
    {
      'meter': '76-80',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '76-80',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '80-84',
      'hoogte': '0-1 wk',
      'value': 32
    },
    {
      'meter': '80-84',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '80-84',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '80-84',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '80-84',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '80-84',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '80-84',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '84-88',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '84-88',
      'hoogte': '1-2 wk ',
      'value': 28
    },
    {
      'meter': '84-88',
      'hoogte': '2-3 wk',
      'value': 29
    },
    {
      'meter': '84-88',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '84-88',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '84-88',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '84-88',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '88-92',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '88-92',
      'hoogte': '1-2 wk ',
      'value': 29
    },
    {
      'meter': '88-92',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '88-92',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '88-92',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '88-92',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '88-92',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '92-96',
      'hoogte': '0-1 wk',
      'value': 32
    },
    {
      'meter': '92-96',
      'hoogte': '1-2 wk ',
      'value': 29
    },
    {
      'meter': '92-96',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '92-96',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '92-96',
      'hoogte': '4-5 wk',
      'value': 24
    },
    {
      'meter': '92-96',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '92-96',
      'hoogte': '> 7 wk',
      'value': 12
    },
    {
      'meter': '96-100',
      'hoogte': '0-1 wk',
      'value': 35
    },
    {
      'meter': '96-100',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '96-100',
      'hoogte': '2-3 wk',
      'value': 28
    },
    {
      'meter': '96-100',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '96-100',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '96-100',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '96-100',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '100-104',
      'hoogte': '0-1 wk',
      'value': 35
    },
    {
      'meter': '100-104',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '100-104',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '100-104',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '100-104',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '100-104',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '100-104',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '104-108',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '104-108',
      'hoogte': '1-2 wk ',
      'value': 30
    },
    {
      'meter': '104-108',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '104-108',
      'hoogte': '3-4 wk',
      'value': 26
    },
    {
      'meter': '104-108',
      'hoogte': '4-5 wk',
      'value': 23
    },
    {
      'meter': '104-108',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '104-108',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '108-112',
      'hoogte': '0-1 wk',
      'value': 31
    },
    {
      'meter': '108-112',
      'hoogte': '1-2 wk ',
      'value': 28
    },
    {
      'meter': '108-112',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '108-112',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '108-112',
      'hoogte': '4-5 wk',
      'value': 25
    },
    {
      'meter': '108-112',
      'hoogte': '5-6 wk',
      'value': 19
    },
    {
      'meter': '108-112',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '112-116',
      'hoogte': '0-1 wk',
      'value': 33
    },
    {
      'meter': '112-116',
      'hoogte': '1-2 wk ',
      'value': 32
    },
    {
      'meter': '112-116',
      'hoogte': '2-3 wk',
      'value': 27
    },
    {
      'meter': '112-116',
      'hoogte': '3-4 wk',
      'value': 25
    },
    {
      'meter': '112-116',
      'hoogte': '4-5 wk',
      'value': 23
    },
    {
      'meter': '112-116',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '112-116',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '116-120',
      'hoogte': '0-1 wk',
      'value': 34
    },
    {
      'meter': '116-120',
      'hoogte': '1-2 wk ',
      'value': 29
    },
    {
      'meter': '116-120',
      'hoogte': '2-3 wk',
      'value': 26
    },
    {
      'meter': '116-120',
      'hoogte': '3-4 wk',
      'value': 27
    },
    {
      'meter': '116-120',
      'hoogte': '4-5 wk',
      'value': 22
    },
    {
      'meter': '116-120',
      'hoogte': '5-6 wk',
      'value': 20
    },
    {
      'meter': '116-120',
      'hoogte': '> 7 wk',
      'value': 10
    },
],
optionsDiameter: {
"title": "diameter tomaten in mm (gemiddeld)",
"axes": {
"bottom": {
	"title": "Afgelegde afstand in pad (meter)",
	"mapsTo": "meter",
	"scaleType": "labels",
	"ticks": {
        "rotation": "always"}
},
"left": {
	"title": "Afstand tot oogstmoment",
	"mapsTo": "hoogte",
	"scaleType": "labels"
}
},
"heatmap": {
"colorLegend": {
	"title": "diameter tomaten (mm)"
}
,
"color": {
	"gradient": {"enabled":true, "colors":['#FF0D0D', '#FF4E11','#FF8E15','#FAB733','#ACB334','#69B34C']}
},
},
"experimental": true,
"height": "400px"
}, dataAantalTomaten:
[
    {
      'meter': '0-4',
      'hoogte': '0-1 wk',
      'value': 8
    },
    {
      'meter': '0-4',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '0-4',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '0-4',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '0-4',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '0-4',
      'hoogte': '5-6 wk',
      'value': 8
    },
    {
      'meter': '0-4',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '4-8',
      'hoogte': '0-1 wk',
      'value': 11
    },
    {
      'meter': '4-8',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '4-8',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '4-8',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '4-8',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '4-8',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '4-8',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '8-12',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '8-12',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '8-12',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '8-12',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '8-12',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '8-12',
      'hoogte': '5-6 wk',
      'value': 11
    },
    {
      'meter': '8-12',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '12-16',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '12-16',
      'hoogte': '1-2 wk ',
      'value': 12
    },
    {
      'meter': '12-16',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '12-16',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '12-16',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '12-16',
      'hoogte': '5-6 wk',
      'value': 11
    },
    {
      'meter': '12-16',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '16-20',
      'hoogte': '0-1 wk',
      'value': 8
    },
    {
      'meter': '16-20',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '16-20',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '16-20',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '16-20',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '16-20',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '16-20',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '20-24',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '20-24',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '20-24',
      'hoogte': '2-3 wk',
      'value': 8
    },
    {
      'meter': '20-24',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '20-24',
      'hoogte': '4-5 wk',
      'value': 8
    },
    {
      'meter': '20-24',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '20-24',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '24-28',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '24-28',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '24-28',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '24-28',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '24-28',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '24-28',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '24-28',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '28-32',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '28-32',
      'hoogte': '1-2 wk ',
      'value': 8
    },
    {
      'meter': '28-32',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '28-32',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '28-32',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '28-32',
      'hoogte': '5-6 wk',
      'value': 8
    },
    {
      'meter': '28-32',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '32-36',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '32-36',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '36-40',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '36-40',
      'hoogte': '1-2 wk ',
      'value': 8
    },
    {
      'meter': '36-40',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '36-40',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '36-40',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '36-40',
      'hoogte': '5-6 wk',
      'value': 11
    },
    {
      'meter': '36-40',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '40-44',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '40-44',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '40-44',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '40-44',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '40-44',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '40-44',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '40-44',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '44-48',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '44-48',
      'hoogte': '1-2 wk ',
      'value': 8
    },
    {
      'meter': '44-48',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '44-48',
      'hoogte': '3-4 wk',
      'value': 11
    },
    {
      'meter': '44-48',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '44-48',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '44-48',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '48-52',
      'hoogte': '0-1 wk',
      'value': 11
    },
    {
      'meter': '48-52',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '48-52',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '48-52',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '48-52',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '48-52',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '48-52',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '52-56',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '52-56',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '52-56',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '52-56',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '52-56',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '52-56',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '52-56',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '56-60',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '56-60',
      'hoogte': '1-2 wk ',
      'value': 8
    },
    {
      'meter': '56-60',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '56-60',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '56-60',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '56-60',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '56-60',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '60-64',
      'hoogte': '0-1 wk',
      'value': 8
    },
    {
      'meter': '60-64',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '60-64',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '60-64',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '60-64',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '60-64',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '60-64',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '64-68',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '64-68',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '64-68',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '64-68',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '64-68',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '64-68',
      'hoogte': '5-6 wk',
      'value': 8
    },
    {
      'meter': '64-68',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '68-72',
      'hoogte': '0-1 wk',
      'value': 11
    },
    {
      'meter': '68-72',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '68-72',
      'hoogte': '2-3 wk',
      'value': 8
    },
    {
      'meter': '68-72',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '68-72',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '68-72',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '68-72',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '72-76',
      'hoogte': '0-1 wk',
      'value': 11
    },
    {
      'meter': '72-76',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '72-76',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '72-76',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '72-76',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '72-76',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '72-76',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '76-80',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '76-80',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '76-80',
      'hoogte': '2-3 wk',
      'value': 8
    },
    {
      'meter': '76-80',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '76-80',
      'hoogte': '4-5 wk',
      'value': 8
    },
    {
      'meter': '76-80',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '76-80',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '80-84',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '80-84',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '80-84',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '80-84',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '80-84',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '80-84',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '80-84',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '84-88',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '84-88',
      'hoogte': '1-2 wk ',
      'value': 11
    },
    {
      'meter': '84-88',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '84-88',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '84-88',
      'hoogte': '4-5 wk',
      'value': 11
    },
    {
      'meter': '84-88',
      'hoogte': '5-6 wk',
      'value': 8
    },
    {
      'meter': '84-88',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '88-92',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '88-92',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '88-92',
      'hoogte': '2-3 wk',
      'value': 11
    },
    {
      'meter': '88-92',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '88-92',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '88-92',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '88-92',
      'hoogte': '> 7 wk',
      'value': 8
    },
    {
      'meter': '92-96',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '92-96',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '92-96',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '92-96',
      'hoogte': '3-4 wk',
      'value': 9
    },
    {
      'meter': '92-96',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '92-96',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '92-96',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '96-100',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '96-100',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '96-100',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '96-100',
      'hoogte': '3-4 wk',
      'value': 8
    },
    {
      'meter': '96-100',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '96-100',
      'hoogte': '5-6 wk',
      'value': 11
    },
    {
      'meter': '96-100',
      'hoogte': '> 7 wk',
      'value': 10
    },
    {
      'meter': '100-104',
      'hoogte': '0-1 wk',
      'value': 8
    },
    {
      'meter': '100-104',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '100-104',
      'hoogte': '2-3 wk',
      'value': 9
    },
    {
      'meter': '100-104',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '100-104',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '100-104',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '100-104',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '104-108',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '104-108',
      'hoogte': '1-2 wk ',
      'value': 8
    },
    {
      'meter': '104-108',
      'hoogte': '2-3 wk',
      'value': 8
    },
    {
      'meter': '104-108',
      'hoogte': '3-4 wk',
      'value': 11
    },
    {
      'meter': '104-108',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '104-108',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '104-108',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '108-112',
      'hoogte': '0-1 wk',
      'value': 10
    },
    {
      'meter': '108-112',
      'hoogte': '1-2 wk ',
      'value': 10
    },
    {
      'meter': '108-112',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '108-112',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '108-112',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '108-112',
      'hoogte': '5-6 wk',
      'value': 9
    },
    {
      'meter': '108-112',
      'hoogte': '> 7 wk',
      'value': 11
    },
    {
      'meter': '112-116',
      'hoogte': '0-1 wk',
      'value': 9
    },
    {
      'meter': '112-116',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '112-116',
      'hoogte': '2-3 wk',
      'value': 10
    },
    {
      'meter': '112-116',
      'hoogte': '3-4 wk',
      'value': 10
    },
    {
      'meter': '112-116',
      'hoogte': '4-5 wk',
      'value': 10
    },
    {
      'meter': '112-116',
      'hoogte': '5-6 wk',
      'value': 22
    },
    {
      'meter': '112-116',
      'hoogte': '> 7 wk',
      'value': 9
    },
    {
      'meter': '116-120',
      'hoogte': '0-1 wk',
      'value': 11
    },
    {
      'meter': '116-120',
      'hoogte': '1-2 wk ',
      'value': 9
    },
    {
      'meter': '116-120',
      'hoogte': '2-3 wk',
      'value': 35
    },
    {
      'meter': '116-120',
      'hoogte': '3-4 wk',
      'value': 11
    },
    {
      'meter': '116-120',
      'hoogte': '4-5 wk',
      'value': 9
    },
    {
      'meter': '116-120',
      'hoogte': '5-6 wk',
      'value': 10
    },
    {
      'meter': '116-120',
      'hoogte': '> 7 wk',
      'value': 15
    },
],
optionsAantalTomaten: {
"title": "aantal tomaten geteld",
"axes": {
"bottom": {
	"title": "Afgelegde afstand in pad (meter)",
	"mapsTo": "meter",
	"scaleType": "labels",
	"ticks": {"rotation": "always"}
},
"left": {
	"title": "Afstand tot oogstmoment",
	"mapsTo": "hoogte",
	"scaleType": "labels"
}
},
"heatmap": {
"colorLegend": {
	"title": "aantal tomaten"
}
},
"experimental": true,
"height": "400px"
},
items: [],
allValues:[]


	};

       
componentDidMount(){
  this.getFirebaseRecordsHarvest()
}


    getFirebaseRecordsHarvest(){
        
        get(child(dbRef, `/stoffels/section-1/row-555/forecast/`)).then((snapshot) => {
         if (snapshot.exists()) {
           this.state.allValues=snapshot.toJSON()
          //  console.log(Object.keys(this.state.allValues));
           Object.keys(this.state.allValues).map(key=>{
            // KeysJson.push({id:key,text:key}) 
            this.state.items.push(key)
            // this.setState({
            //   items:[...this.state.items, key]
            // });
           })
           console.log(this.state.items);


         } else {
           console.log("No data available");
         }
       }).catch((error) => {
         console.error("ERROR",error);
       });   
          
      }
    render = () => (
      <div className="bx--grid bx--grid--full-width">
        <div className="bx--row" style={{marginTop:"10%"}}>
          
        <div style={{ width: 400 }}>
        <Dropdown
  items={this.state.items}
  itemToElement={(item) =>
    item ? (
      <span className="test" style={{ color: 'red' }}>
        {item.text} 🔥
      </span>
    ) : (
      ''
    )
  }
  label="Select an option..."
/>
  </div>
        </div>
			<div className="bx--row ">
				  <h3 style={{textAlign:"left"}}>prod 2 - rij 362 - links</h3>
      </div>    
      <div className="bx--row ">                      
          <h6 style={{textAlign:"left"}}> laatste update: 22-05-22 17:40 </h6>
			</div>
			<div className="bx--row ">
                    <div className="bx--col-lg-11 ">	
					    <img src={CountingImage} alt="Counting tomatoes" height="40"></img>
						<HeatmapChart
							data={this.state.dataAantalTomaten}
							options={this.state.optionsAantalTomaten}>
						</HeatmapChart>
					</div>
			</div>
			
			<div className="bx--row ">
                    <div className="bx--col-lg-11 ">
					    <img src={RipingImage} alt="Riping proces of tomatoes" height="40"></img>
						<HeatmapChart
							data={this.state.dataPercentage}
							options={this.state.optionsPercentage}>
						</HeatmapChart>
					</div>
			</div>
			
			<div className="bx--row ">
                    <div className="bx--col-lg-11 ">
					<img src={DiameterImage} alt="Diameter measurement tomatoes" height="40"></img>
					<HeatmapChart
						data={this.state.dataDiameter}
						options={this.state.optionsDiameter}>
					</HeatmapChart>
					</div>
			</div>
		</div>
		
                	

	);
};

export default HarvestPredictionRow;