import yields from "../../assets/yields.json"

import {   
  ComboChart
} from "@carbon/charts-react";
import { PropTypes } from "prop-types";
const yieldData = yields

const optionsChart = {
  title: "Oogstprognose 2024",
  axes: {
    left: {
      mapsTo: "value",
      title: "Oogst (kg/m2)",
    },
    bottom: {
      mapsTo: "key",
      scaleType: "labels",
      title: "Week"
    },
  },
  color: {
    scale: {
      "Oogst-Serre-2": "#4169E1",  
      "Oogst-lijn": "#32CD32",
      "Prognose-lijn":" #9370DB"
    },
  },
  comboChartTypes: [
    {
      type: "area",
      options: {
        points: {
          enabled: true,
        },
      },
      correspondingDatasets: ["Oogst-lijn"],
    },
    {
      type: "area",
      options: {
        points: {
          enabled: true,
        },
      },
      correspondingDatasets: ["Prognose-Vitalion"],
    },
    {
      type: "area",
      options: {
        points: {
          enabled: true,
        },
      },
      correspondingDatasets: ["Oogst-Serre-2"],
    },
    {
      type: "line",
      options: {
        points: {
          enabled: true,
        },
      },
      correspondingDatasets: ["Prediction-corr"],
    },
  ],
  height: "360px",
};

// Accept 'data' prop for dynamic data
const HarvestPrediction = ({ data }) => {
  return(
    <ComboChart 
      data={data} // Use the 'data' prop here
      options={optionsChart}
    />
  )
}

// Define PropTypes for the component
HarvestPrediction.propTypes = {
  data: PropTypes.array.isRequired
};

// Set default props in case 'data' is not passed
HarvestPrediction.defaultProps = {
  data: [] // default to an empty array if no data is provided
};

export default HarvestPrediction;