import useWeatherAppStore from "../../../hooks/UseWeatherApp";

export default function DateSelector() {
  const {
    date,
    tzOffset,
    activeDateIndex,
    setActiveDateIndex,
    setActiveHourIndex,
    setCurrentHour
  } = useWeatherAppStore();
  
  function handleDateClick(index) {
    setActiveDateIndex(index);
  
    if (index !== 0) {
      setActiveHourIndex(2);
      const selectedDate = new Date(date.getTime());
  
      // Calculate the new date considering the month's days
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const day = selectedDate.getDate();
      const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the last day of the current month
  
      let newDay = day + index;
      if (newDay > daysInMonth) {
        // If the new day exceeds the current month's days, adjust the date accordingly
        newDay -= daysInMonth;
        selectedDate.setMonth(month + 1);
        // If the month was December, it should now be January of the next year
        if (selectedDate.getMonth() === 0) {
          selectedDate.setFullYear(year + 1);
        }
      }
      selectedDate.setDate(newDay);
      selectedDate.setHours(12 - tzOffset, 0, 0, 0);
  
      setCurrentHour(selectedDate.toISOString().slice(0, 13));
    } else {
      setActiveHourIndex(0);
      setCurrentHour(date.toISOString().slice(0, 13));
    }
  }
  
  

  const dateElems = []

  for (let i = 0; i < 7; i++) {
    const displayDate = new Date(date.getTime());
    displayDate.setDate(date.getDate() + i);
    const displayDay = displayDate.getDate();
    const isToday = i === 0;
    
    dateElems.push(
      <div 
        key={displayDate.toISOString()} // Unique key based on the date
        className="cds--col" 
        style={{
          paddingRight: "0.2rem",
          paddingLeft: "0.8rem"
        }}
        id="datepicker-column"
      >
        <h6 
          className={`date ${!isToday ? "secondary-text" : ""}`} 
          onClick={() => handleDateClick(i)}
        >
          {isToday ? "Today" : displayDay}
        </h6>
      </div>
    );
  }

  return (
    <div className="cds--grid no-padding" >
      <div className="cds--row">
        {dateElems}
      </div>
    </div>
  )
}