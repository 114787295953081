import React from "react";
import { AreaChart,SimpleBarChart,HistogramChart,HeatmapChart,ComboChart, StackedBarChart, GroupedBarChart } from "@carbon/charts-react";
import { Link } from 'react-router-dom'
import { DataTable,Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarSearch,
  TableToolbarContent,
  TableToolbarMenu,
  TableToolbarAction,
  TableExpandHeader,
  TableSelectAll,
  TableExpandedRow,
  TableExpandRow,
  TableSelectRow, 
  Button, 
  Tile,
  TableRow } from '@carbon/react';

import "@carbon/charts/styles.css";
import "./plex-and-carbon-components.css";
import { Grid, Row, Column } from '@carbon/react';


class LayoutProduction2 extends React.Component {

  render = () => (
    <div className="bx--grid bx--grid--full-width">
    <div className="bx--row">      
        <h3 style={{textAlign:"left"}}>plattegrond productieruimte 2</h3>        
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-6 ">
        <Tile style={{ overflowY:"auto", background: "#ECECEC", borderRadius: "10px"}} >
          <div className="bx--row">
            <div className="bx--col-lg-2 ">
              productieruimte 1 / uitgang    
            </div>    
          </div>
          <div className="bx--row">
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 351 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 352 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> <h6 style={{color:"red"}}>353</h6>  </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 354 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 355 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 356 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 357 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 358 </Button>
          </div>  
          <div className="bx--row">
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 359 </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 360 </Button>
          </div>  
          <div className="bx--row">            
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 361 </Button>            
            <Button disabled size='sm'></Button>
            <Link to="/HarvestPredictionDepartment" style={{ textDecoration: 'none', color:"blue" }}> 
              <Button size='sm' kind="tertiary" style={{width:"400px"}}> 362 </Button>
            </Link>  
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 363 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 364 </Button>
          </div>  
          <div className="bx--row">
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 365 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 366 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 367 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 368 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 369 </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 370 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 371 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 372 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 373 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 374 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 375 </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 376 </Button>
          </div>  
          <div className="bx--row">
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 377 </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 378 </Button>
          </div>  
          <div className="bx--row">
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 379 </Button>
            <Button disabled size='sm'></Button>
            <Button disabled size='sm' kind="tertiary" style={{width:"400px"}}> 380 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 381 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 382 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 383 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 384 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 385 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 386 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 387 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 388 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 389 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 390 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 391 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 392 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 393 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 394 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 395 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 396 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 397 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 398 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 399 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 400 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 401 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 402 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 403 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 404 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 405 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 406 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 407 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 408 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 409 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 410 </Button>
          </div>         
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 411 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 412 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 413 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 414 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 415 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 416 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 417 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 418 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 419 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 420 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 421 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 422 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 423 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 424 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 425 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 426 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 427 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 428 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 429 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 430 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 431 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 432 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 433 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 434 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 435 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 436 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 437 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 438 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 439 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 440 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 441 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 442 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 443 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 444 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 445 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 446 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 447 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 448 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 449 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 450 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 451 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 452 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 453 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 454 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 455 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 456 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 457 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 458 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 459 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 460 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 461 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 462 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 463 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 464 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 465 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 466 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 467 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 468 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 469 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 470 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 471 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 472 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 473 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 474 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 475 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 476 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 477 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 478 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 479 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 480 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 481 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 482 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 483 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 484 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 485 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 486 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 487 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 488 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 489 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 490 </Button>
          </div>  
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 491 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 492 </Button>
          </div>
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 493 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 494 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 495 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 496 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 497 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 498 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 499 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 500 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 501 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 502 </Button>
          </div> 
          <div className="bx--row">
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 503 </Button>
            <Button disabled size='sm'></Button>
            <Button size='sm' kind="tertiary" style={{width:"400px"}}> 504 </Button>
          </div> 
          <div className="bx--row">
            <div className="bx--col-lg-2 ">
                Productieruimte 3
            </div>  
          </div> 
        </Tile>
        </div>
      </div>
    </div>    
	);
};
		              
export default LayoutProduction2;



    
    
            
