import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthErrorCodes } from "firebase/auth";
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useAuth } from "../../utils/AuthContext";

import logo from "../../assets/images/logo-white.png";

import { 
  InlineNotification,
  Button,
  Form,
  FormGroup,
  Stack,
  TextInput,
  Theme
} from "@carbon/react"


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await auth.login(email, password);
      navigate("/home");
    } catch (err) {
      if (
        err.code === AuthErrorCodes.INVALID_PASSWORD ||
        err.code === AuthErrorCodes.USER_DELETED
      ) {
        setError("The email address or password is incorrect");
      } else {
        setError("Something went wrong. Try Again.")
      }
    }
  };

  return (
    <div style={{position:"absolute", width:"100vw", height:"100vh", backgroundColor:"black", zIndex:"-2000"}}>
      <div className="cds--grid bx--grid--full-width no-padding">
        <div className="cds--row" style={{margin:"3rem"}}>
          <div className="cds--col-lg-7 no-padding" >
            <div style={{
                backgroundImage: "linear-gradient(125deg, rgba(0,0,0,1) 70%, rgba(60,60,60,1) 100%)",
                position: "absolute", 
                top: "48%", 
                transform:"translateY(-50%)", 
                borderRadius: "50px",
                width: "40vw",
                left: "0",
                height: " 650px",
              }}
              >
                <div style={{
                    backgroundColor: "black",
                    position: "relative", 
                    top: "50%", 
                    transform:"translateY(-50%)", 
                    borderRadius: "50px",
                    width: "99.5%",
                    height: " 99.5%",
                  }}
                >
                  <div 
                    style={{
                      position: "absolute", 
                      top: "48%", 
                      transform:"translateY(-50%)", 
                      paddingLeft: "3.5rem"
                    }}
                  >
                    <div className="cds--row" >
                      <div className="cds--col-lg-16" style={{paddingBottom: "3.5rem"}}>
                        <h2 className="main-text" style={{fontWeight:"500"}}>Log in to your account</h2>
                      </div>
                    </div>
                    {error && <InlineNotification variant="danger">{error}</InlineNotification>}
                    <Form onSubmit={handleSubmit}>
                      <FormGroup style={{ width: '350px' }}>
                        <Stack gap={8}>
                          <TextInput
                            id="one"
                            labelText="Enter your email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <TextInput
                            id="two"
                            labelText="Enter your password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}

                          />
                          <button className="cds--btn cds--btn--primary btn-updated" type="submit">
                            Sign in
                          </button>
                        </Stack>
                        <div style={{paddingTop:"2rem"}}>
                          <Stack gap={4} >
                            <h5 className="link">
                              Forgot password?
                            </h5>
                            <h5 style={{fontWeight: "400"}}>
                              Don't have an account?
                              &nbsp;&nbsp;&nbsp;
                              <Link to="/signup">
                                <h5 className="link">Sign up</h5>
                              </Link>
                            </h5>
                          </Stack>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="cds--col" style={{paddingLeft:"0"}}>
            <div style={{
                backgroundImage: "linear-gradient(-35deg, rgba(0,0,0,1) 70%, rgba(60,60,60,1) 100%)",
                position: "absolute", 
                top: "48%", 
                transform:"translateY(-50%)", 
                borderRadius: "50px",
                width: "60vw",
                height: " 604px",
                right: "0",
                zIndex: "2"
              }}
              >
                <div style={{
                    backgroundColor: "black",
                    position: "relative", 
                    top: "50%", 
                    transform:"translateY(-50%)", 
                    borderRadius: "50px",
                    width: "99%",
                    height: " 99%",
                  }}
                >
              <div style={{position: "absolute", top: "46%", transform:"translateY(-50%)", right:"7rem"}}>
                <img src={logo} alt="Logo" style={{height: "60px"}}/>
              </div>
              <h3 className="main-text" style={{position: "absolute", top: "56%", transform:"translateY(-50%)", right:"11rem"}}>AI Powered Greenhouse Solution</h3>
            </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Login;