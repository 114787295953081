import { openDB } from 'idb';

export async function initDB() {
  const db = await openDB('MyDatabase', 1, {
    upgrade(db) {
      db.createObjectStore('apiData');
    },
  });
}

initDB();

// In indexedDB.js

export async function storeAPIData(key, data) {
    const db = await openDB('MyDatabase', 1);
    const tx = db.transaction('apiData', 'readwrite');
    const timestamp = new Date().getTime();
    tx.store.put({ data, timestamp }, key);
    await tx.done;
  }
  
  export async function getAPIData(key, timeLimit) {
    const db = await openDB('MyDatabase', 1);
    const cachedData = await db.get('apiData', key);
    const currentTime = new Date().getTime();
  
    if (cachedData && (currentTime - cachedData.timestamp < timeLimit)) {
      return cachedData.data;
    }
    return null;
  }
  