import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getStorage } from "firebase/storage"
import { getDatabase, ref } from "firebase/database";

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTHDOMAIN}`,
    projectId: `${process.env.REACT_APP_PROJECTID}`,
    storageBucket:`${ process.env.REACT_APP_STORAGEBUCKET}`,
    messagingSenderId:`${process.env.REACT_APP_MESSAGINGSENDERID}`,
    appId: `${process.env.REACT_APP_APPID}`,
    measurementId:`${ process.env.REACT_APP_MEASUREMENTID}`,
    databaseURL:`${ process.env.REACT_APP_DATABASE_URL}`
  };

  const app = initializeApp(firebaseConfig);
  
  const auth = getAuth(app);
  const database = getDatabase(app);
  const storage = getStorage(app)
  const dbRef = ref(getDatabase());
  
  export { 
    auth,
    app,
    database,
    dbRef,
    storage
}
  